import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import { GoogleOAuthProvider } from '@react-oauth/google';
//// Import icon styles
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

//// Import page styles
// General layout and initialization
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import './_metronic/assets/sass/styles/home.scss'
import './_metronic/assets/sass/styles/header.scss'
import './_metronic/assets/sass/styles/overview.scss'
import './_metronic/assets/sass/styles/search.scss'
import './_metronic/assets/sass/styles/userprofile.scss'
import './_metronic/assets/sass/styles/testScoreStrategy.scss'
import './_metronic/assets/sass/styles/signin_signup.scss'
import './_metronic/assets/sass/styles/advisor.scss'
import './_metronic/assets/sass/styles/marketing.scss'

import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <GoogleOAuthProvider clientId="604695189815-67oosh6jg2511phhij81jlsvkrkqe8i1.apps.googleusercontent.com">
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </MetronicI18nProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </GoogleOAuthProvider>
  )
}
