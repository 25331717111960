import { useEffect, useState } from 'react'
import { AdmissionInfo } from './TabComponents/AdmissionInfo'
import { GPAData } from './TabComponents/GPAData'
import { RnDSpend } from './TabComponents/R&DSpend'
import { Safety } from './TabComponents/Safety'
import { SATScoreData } from './TabComponents/SATScoreData'
import { SpecializedRankings } from './TabComponents/SpecializedRankings'
import { IndustryRankings } from './TabComponents/IndustryRankings'
import { ACTScoreData } from './TabComponents/ACTScoreData'

const CollegeDetailTabContent = ({ selectedTab, setSelectedTab, tabOptions, college, collegeListLength, collegesId, setPrompt, setNavigateTo }) => {

  const [cds, setCds] = useState<any>([])
  const [rndAnnual, setRndAnnual] = useState<any>([])
  const [crimeAnnual, setCrimeAnnual] = useState<any>([])

  useEffect(() => {

    let cdsArr: any[] = []
    if (college && college.college_cds) {
      college.college_cds.forEach((res, index) => {
        if (index < 3) {
          cdsArr.push(res);
          cdsArr.sort((a, b) => a.year - b.year);
          setCds(cdsArr)
        }
      })
    }

    let rndArr: any[] = []
    if (college && college.rnd_annual) {
      college.rnd_annual.forEach((res, index) => {
        if (index < 3) {
          rndArr.push(res);
          setRndAnnual(rndArr)
        }
      })
    };

    let crimeArr: any[] = []
    if (college && college.crime_annual) {
      college.crime_annual.forEach((res, index) => {
        crimeArr.sort((a, b) => b.year - a.year)
        crimeArr.sort((a, b) => b.location - a.location)
        crimeArr.push(res)
        setCrimeAnnual(crimeArr)
      })
    }

    // setRnd(college.rnd_average[0])
  }, [college])

  switch (selectedTab) {
    case tabOptions[0]:
      return <AdmissionInfo college={college} cds={cds} />
    case tabOptions[1]:
      return <GPAData cds={cds} />
    case tabOptions[2]:
      return <SATScoreData cds={cds} collegeName={college.name} setPrompt={setPrompt} setNavigateTo={setNavigateTo} />
    case tabOptions[3]:
      return <ACTScoreData cds={cds} collegeName={college.name} setPrompt={setPrompt} setNavigateTo={setNavigateTo} />
    case tabOptions[4]:
      return <IndustryRankings college={college} />
    case tabOptions[5]:
      return <SpecializedRankings
        college={college}
        collegeListLength={collegeListLength}
        setSelectedTab={setSelectedTab} />
    case tabOptions[6]:
      return <RnDSpend
        college={college}
        rndAnnual={rndAnnual}
        collegeListLength={collegeListLength}
        setSelectedTab={setSelectedTab} />
    case tabOptions[7]:
      return <Safety
        college={college}
        crimeAnnual={crimeAnnual}
        collegeListLength={collegeListLength}
        collegesId={collegesId}
        setSelectedTab={setSelectedTab} />
    default:
      return <AdmissionInfo college={college} cds={cds} />
  }

}

export { CollegeDetailTabContent }