import { useEffect, useState } from "react"
import { LoadingSpinner } from "../LoadingSpinner"
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { updatePassword, login, resendEmailVerificationRequest } from "../../modules/auth/core/_requests"
import { URL, returnItemFromProductID, specializedRankingsPriceID, safetyPriceID, rdSpendPriceID, pageRoutes, ecPremiumPriceIDs, advisorPriceIDs } from "../dictionary"
import axios from "axios"
import { useNavigate } from "react-router"
import { AuthReactGAHandlers } from "../ReactGAHandlers"
import { getUserByToken } from "../../modules/auth/core/_requests"
import { getAuth } from "../../modules/auth"


const AccountManagement = ({ user, userData, setCollegeDetailSelectedTab, socialAuthUser }) => {

  const navigate = useNavigate()
  const { handleEmailVerificationResendRequest } = AuthReactGAHandlers()
  const lineItemStyle = { paddingTop: '15px', paddingBottom: '15px', marginLeft: '30px' }

  const buttonStyle = { maxWidth: '200px', borderRadius: '5px', border: 'transparent', marginRight: '30px', padding: '8px', color: 'rgba(140,140,140,1)', cursor: 'pointer' }

  const [updatePassRequest, setUpdatePassRequest] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [password, setPassword] = useState('password')
  const [eye, setEye] = useState(false)
  const [passRequestStatus, setPassRequestStatus] = useState('')
  const [userPurchases, setUserPurchases] = useState<any>([])
  const [emailResendClicked, setEmailResendClicked] = useState(false)

  const eyeTrue = (<i className="bi bi-eye"></i>)
  const eyeFalse = (<i className="bi bi-eye-slash"></i>)

  const handlePasswordView = () => {
    if (password === 'password') {
      setEye(true);
      setPassword('text')
    }
    else {
      setEye(false);
      setPassword('password')
    }
  }

  const eyeComponent = (
    <span className='ms-4 align-self-center' onClick={handlePasswordView}>
      {eye ? eyeTrue : eyeFalse}
    </span>
  )

  const formSchema = Yup.object().shape({
    oldPass: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .notOneOf([Yup.ref('newPass1'), Yup.ref('newPass2')], 'New password must be different than your original password'),
    newPass1: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    newPass2: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('newPass1')], 'Passwords must match'),
  })

  let initialValues = { oldPass: '', newPass1: '', newPass2: '' }

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus(null);

      let loginCheck = false

      try {

        const { data: authToken } = await login(user.emailAddress, values.oldPass);

        loginCheck = true

      } catch (error: any) {

        console.error(error);

        if (error.response && error.response.status && error.response.status == 400) {
          setStatus('Original password is incorrect')
        } else {
          setStatus('Could not update password at this time. Please try again later');
        }

        loginCheck = false

        setLoading(false)

      }

      if (loginCheck == true) {

        try {

          await updatePassword(values.newPass1, values.newPass2, values.oldPass);

          setPassRequestStatus('Password successfully updated');
          setUpdatePassRequest(false)

        } catch (error) {

          console.log(error)

          setStatus('Could not update password at this time. Please try again later');

        } finally {
          setLoading(false)
        }

      }

    }
  });

  const getStripePrice = async (priceID) => {
    let res = await axios.get(`${URL}/get-price/${priceID}`)
    let unitAmount = res.data.unit_amount
    if ((unitAmount / 100) > 0) {
      unitAmount = (unitAmount / 100).toFixed(2)
    }
    return unitAmount
  }

  const handleNav = (priceID) => {

    if ([specializedRankingsPriceID, rdSpendPriceID, safetyPriceID].includes(priceID)) {

      switch (true) {
        case priceID == specializedRankingsPriceID:
          setCollegeDetailSelectedTab('Specialized Rankings');
          navigate(pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology'));
          break
        case priceID == rdSpendPriceID:
          setCollegeDetailSelectedTab('R&D Spend');
          navigate(pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology'));
          break
        case priceID == safetyPriceID:
          setCollegeDetailSelectedTab('Crime & Safety');
          navigate(pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology'));
          break
      }

    } else {

      try {
        let ecObj = ecPremiumPriceIDs.find(item => item.priceID == priceID)
        let advisorObj = advisorPriceIDs.find(item => item.priceID == priceID)

        if (ecObj) {
          navigate(`${pageRoutes.ecPremiumInsightsWithId(ecObj.activityID)}`)
        }

        if (advisorObj) {
          navigate(`${pageRoutes.advisorDetailWithId(advisorObj.advisorID)}`)
        }

      } catch (error) {
        console.log('Error converting EC productID to a valid product', error)
      }

    }

  }

  const returnAdvisorPurchaseCheck = (priceID) => {
    let advisorObj = advisorPriceIDs.find(item => item.priceID == priceID)

    if (advisorObj) {
      return true
    }

    return false
  }

  useEffect(() => {
    const fetchUserPurchases = async () => {
      if (dataLoading) {
        setDataLoading(false);
      }

      try {
        let res = await axios.get(`${URL}/user_transactions/${user.user_id}`);
        let purchases = res.data || [];
        console.log('purchases', purchases)

        let updatedPurchases = await Promise.all(
          purchases.map(async (purchase) => {
            let originalProductID = purchase.productID;
            // Convert purchase date time
            purchase.purchaseDateTime = new Date(purchase.purchaseDateTime).toISOString().split('T')[0];

            // Convert item from product ID
            purchase.productID = returnItemFromProductID(originalProductID);

            // Retrieve cost
            purchase.cost = await getStripePrice(originalProductID);

            // Set nav, use handleNav function
            purchase.nav = originalProductID

            return purchase;
          })
        );

        setUserPurchases(updatedPurchases);
      } catch (error) {
        console.error('Error fetching user purchases:', error);
      }

      setDataLoading(true);
    };

    fetchUserPurchases();
  }, [user]);

  const returnFormattedDate = (dateData) => {
    if (!dateData) {
      return '-'
    }

    const [year, month, day] = dateData.split('-');
    const formattedDate = `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`;

    return `${formattedDate}`;
  }

  const userPurchaseTable = (userPurchases) => {
    switch (true) {
      case userPurchases.length > 0:
        return (
          <div style={{ paddingLeft: '35px' }}>
            <table className="table table-borderless">
              <thead>
                <tr className='fw-bold text-left'>
                  <th scope="col">Purchase Date</th>
                  <th scope="col">Item</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Access End Date</th>
                </tr>
              </thead>
              <tbody>
                {userPurchases.map((res, index) => (
                  <tr
                    key={index}
                    className="text-center align-middle"
                    style={{ borderBottom: '1px solid #DBDFE9' }}>
                    <th scope='row' className="text-start">{returnFormattedDate(res.purchaseDateTime)}</th>
                    <th scope='row' className="text-start"><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleNav(res.nav)}>{res.productID}</span></th>
                    <th scope='row' className="text-start">{res.cost ? `$${res.cost}` : '-'}</th>
                    {/* Use res.nav as it holds the original priceID to be passed to the advisor purchase check. If product is an advisor consultation, returns true and we indicate an N/A end date otherwise return the original formatted date */}
                    <th scope='row' className="text-start">{returnAdvisorPurchaseCheck(res.nav) ? "N/A" : returnFormattedDate(res.expiryDate)}</th>
                  </tr>))}
              </tbody>
            </table>
          </div>)
      case userPurchases.length == 0 || !userPurchases:
        return (
          <div style={{ paddingLeft: '35px', margin: '2vh 0 2vh 0' }}>There are no items in your purchase history at this time.</div>
        )
    }
  }

  useEffect(() => {
    if (emailResendClicked) {
      setEmailResendClicked(false)
    }
  }, [])

  return user ? (
    <div className="d-flex flex-column">
      <div className="dca-userProfile-form-card">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="dca-userProfile-form-title">Account Management</div>

        </div>
        <div className="separator"></div>

        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column dca-font-M" style={lineItemStyle}>
            <div style={{ fontSize: '21px' }}>Email Address</div>
            <div style={{ fontSize: '19px', color: 'rgba(140,140,140,1)' }}>{user.emailAddress}</div>
            {!user.validatedEmail && <div style={{ fontSize: '19px', color: 'rgb(192, 0, 0)', marginTop: '2vh' }}>This email address has not yet been verified. Please verify by clicking the button in the email message you received when initially registering this account. You may also request to <span
              style={{
                color: !emailResendClicked ? '#0070E0' : '',
                cursor: !emailResendClicked ? 'pointer' : ''
              }}
              onClick={() => {
                const handleResendFlow = () => {
                  resendEmailVerificationRequest(user?.firstName, user?.emailAddress);
                  handleEmailVerificationResendRequest();
                  setEmailResendClicked(true)
                }
                if (!emailResendClicked) {
                  handleResendFlow()
                }
              }}>resend</span> an email verification message.</div>}

            {(!user.validatedEmail && emailResendClicked) && <div style={{ fontSize: '19px', marginTop: '2vh' }}>An email has been sent to you, please click on the button in the message to verify your email. Please check your spam folder if you don't receive the message in the next few minutes. Please contact us at <a href="mailto:support@nextfour.ai">support@nextfour.ai</a> if you require any assistance with your account.</div>}
          </div>
          {/* <button style={buttonStyle} className="dca-font-M align-self-center">Change Email</button> */}
        </div>

        <div className="separator align-self-center" style={{ width: '95%' }}></div>

        {!socialAuthUser && <div className={updatePassRequest ? "d-flex flex-column" : "d-flex flex-row justify-content-between"}>
          <div className="d-flex flex-column dca-font-M" style={lineItemStyle}>
            <div style={{ fontSize: '21px' }}>Password</div>
            <div style={{ fontSize: '19px', color: 'rgba(140,140,140,1)' }}></div>

            {passRequestStatus != '' && <div className="dca-font-M" style={{ fontSize: '19px', color: '#81d084', marginTop: '15px' }}>{passRequestStatus}</div>}
          </div>

          {updatePassRequest === false && <button
            style={buttonStyle}
            onClick={() => {
              setUpdatePassRequest(true)
              console.log('updatePassrequest', updatePassRequest)
            }}
            className="align-self-center dca-font-M"
          >Reset Password</button>}

          {updatePassRequest === true && <div>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              style={{ marginLeft: '35px' }}
              id='kt_password_update_form'
            >

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger' style={{ width: '80%' }}>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='fv-row mb-3'>
                <div className="d-flex flex-row" style={{ width: '80%' }}>
                  <input
                    type={password}
                    autoComplete='off'
                    placeholder='Old Password'
                    {...formik.getFieldProps('oldPass')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.oldPass && formik.errors.oldPass,
                      },
                      {
                        'is-valid': formik.touched.oldPass && !formik.errors.oldPass,
                      }
                    )}
                  />{eyeComponent}</div>
                {formik.touched.oldPass && formik.errors.oldPass && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.oldPass}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3'>
                <div className="d-flex flex-row" style={{ width: '80%' }}>
                  <input
                    type={password}
                    autoComplete='off'
                    placeholder='New Password'
                    {...formik.getFieldProps('newPass1')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.newPass1 && formik.errors.newPass1,
                      },
                      {
                        'is-valid': formik.touched.newPass1 && !formik.errors.newPass1,
                      }
                    )}
                  />{eyeComponent}</div>
                {formik.touched.newPass1 && formik.errors.newPass1 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPass1}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3'>
                <div className="d-flex flex-row" style={{ width: '80%' }}>
                  <input
                    type={password}
                    autoComplete='off'
                    placeholder='Confirm new Password'
                    {...formik.getFieldProps('newPass2')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.newPass2 && formik.errors.newPass2,
                      },
                      {
                        'is-valid': formik.touched.newPass2 && !formik.errors.newPass2,
                      }
                    )}
                  />{eyeComponent}</div>
                {formik.touched.newPass2 && formik.errors.newPass2 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPass2}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-grid mb-10'>
                <div className="d-flex flex-row">
                  <button
                    className='dca-userProfile-discard-changes-button text-muted'
                    style={{ maxWidth: 'fit-content', justifySelf: 'start' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setUpdatePassRequest(false)
                    }}>Discard</button>

                  <button
                    type='submit'
                    id='kt_password_update_submit'
                    className='dca-userProfile-save-changes-button'
                    style={{ maxWidth: 'fit-content', justifySelf: 'start', marginLeft: '10px' }}
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Update</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>

            </form>
          </div>}
        </div>}



      </div>

      <div className="dca-userProfile-form-card">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="dca-userProfile-form-title">Purchase History</div>

        </div>
        <div className="separator"></div>

        <div>{dataLoading ? userPurchaseTable(userPurchases) : <LoadingSpinner title={'Retrieving User Purchase History...'} />}</div>

      </div>
    </div>
  ) : <LoadingSpinner title={'Profile Info'} />
}

export { AccountManagement }
