import { useCallback } from "react";
import ReactGA from 'react-ga4'

const categories = {
  tab: 'Tab',
  save: 'Save',
  chart: 'ChartInteraction',
  purchase: 'PurchaseFlow',
  filter: 'FilterControl',
  extLink: 'OpenExtLink',
  overlay: 'Overlay',
  auth: 'AuthFlow'
}

// Add more for post stripe actions
const actions = {
  click: 'Click',
  confirmation: 'Event Confirmation'
}

export const CollegeReactGAHandlers = () => {

  const handleGAEventTabClick = useCallback((labelName, collegeID) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: collegeID != null ? `College ${collegeID} Detail ${labelName} Clicked` : `College Detail ${labelName} Clicked`
    })
  }, [])

  const handleGAEventChartTabClick = useCallback((collegeID, tabName) => {
    ReactGA.event({
      category: categories.chart,
      action: actions.click,
      label: collegeID != null ? `College ${collegeID} Detail ${tabName} Charts Click` : `College Detail ${tabName} Charts Click`
    })
  }, [])

  const handleGAEventDetailsSave = useCallback((collegeID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: collegeID != null ? `College ${collegeID} Detail Save Button Click` : `College Detail Save Button Click`
    })
  }, [])

  const handleGAEventSearchSave = useCallback((collegeID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: collegeID != null ? `College Search ${collegeID} Save Button Click` : `College Detail Save Button Click`
    })
  }, [])

  const handleTestScoreStrategyGAEventSave = useCallback((collegeID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: collegeID != null ? `Test Strategy ${collegeID} Save Button Click` : `Test Strategy Save Button Click`
    })
  }, [])

  const handleGAEventPurchaseModalOpen = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Purchase Modal Displayed`
    })
  }, [])

  const handleGAEventPurchaseModalPurchase = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Purchase Modal Purchase Click`
    })
  }, [])

  const handleGAEventPurchaseModalClose = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Purchase Modal Close Click`
    })
  }, [])

  const handleGAEventCheckoutModalClose = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Checkout Modal Close Click`
    })
  }, [])

  const handleGAEventPostTransactionModalClose = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Post-Transaction Modal Close Click`
    })
  }, [])

  const handleGAEventPurchasePreviewOpen = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Purchase Modal Preview Click`
    })
  }, [])

  const handleGAEventPurchaseComplete = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.confirmation,
      label: `${tabName} Report Purchase`
    })
  }, [])

  const handleGAEventClickPayButtonCheckout = useCallback((tabName) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `${tabName} Checkout Modal Pay Button Click`
    })
  }, [])

  const handleGAEventDetailByRoundGenderClick = useCallback((collegeID) => {
    ReactGA.event({
      category: categories.chart,
      action: actions.click,
      label: `College ${collegeID} Detail By Round Gender Click`
    })
  }, [])

  return {
    handleGAEventTabClick,
    handleGAEventChartTabClick,
    handleGAEventDetailsSave,
    handleGAEventSearchSave,
    handleTestScoreStrategyGAEventSave,
    handleGAEventPurchaseModalOpen,
    handleGAEventPurchaseModalPurchase,
    handleGAEventPurchaseModalClose,
    handleGAEventCheckoutModalClose,
    handleGAEventPostTransactionModalClose,
    handleGAEventPurchasePreviewOpen,
    handleGAEventPurchaseComplete,
    handleGAEventClickPayButtonCheckout,
    handleGAEventDetailByRoundGenderClick
  }
}

export const CollegeDataVizReactGAHandlers = () => {

  const handleGAEventSelectChartOption = useCallback((chart) => {
    ReactGA.event({
      category: categories.chart,
      action: actions.click,
      label: `Selecting ${chart} chart in Chart Option`
    })
  }, [])

  const handleGAEventSelectCollegeSet = useCallback((set) => {
    ReactGA.event({
      category: categories.chart,
      action: actions.click,
      label: `Selecting ${set} as College Set`
    })
  }, [])

  return {
    handleGAEventSelectChartOption,
    handleGAEventSelectCollegeSet
  }
}

export const MajorReactGAHandlers = () => {

  const handleGAEventSearchSave = useCallback((majorID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: majorID != null ? `Majors Search ${majorID} Save Button Click` : `Majors Search Save Button Click`
    })
  }, [])

  const handleGAEventDetailsSave = useCallback((majorID, programID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: majorID != null && programID != null ? `Majors Details Major: ${majorID} Program: ${programID} Save Button Click` : `Majors Details Save Button Click`
    })
  }, [])

  const handleGAEventShowRelated = useCallback(() => {
    ReactGA.event({
      category: categories.filter,
      action: actions.click,
      label: 'Major Details Show Related Majors Control Click'
    })
  }, [])

  const handleGAEventExtLink = useCallback((programID) => {
    ReactGA.event({
      category: categories.extLink,
      action: actions.click,
      label: programID != null ? `Major Details ${programID} More Info Link Click` : `Major Details More Info Link Click`
    })
  }, [])

  return {
    handleGAEventSearchSave,
    handleGAEventDetailsSave,
    handleGAEventShowRelated,
    handleGAEventExtLink
  }
}

export const ECReactGAHandlers = () => {

  const handleGAEventSearchSave = useCallback((activityID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: activityID != null ? `EC Search ${activityID} Save Button Click` : `EC Search Save Button Click`
    })
  }, [])

  const handleGAEventDetailsSave = useCallback((activityID) => {
    ReactGA.event({
      category: categories.save,
      action: actions.click,
      label: activityID != null ? `EC Details ${activityID} Save Button Click` : `EC Details Save Button Click`
    })
  }, [])

  const handleGAEventExtLink = useCallback((activityID) => {
    ReactGA.event({
      category: categories.extLink,
      action: actions.click,
      label: activityID != null ? `EC Search ${activityID} Website Link Click` : `EC Search Website Link Click`
    })
  }, [])

  const handleGAEventViewPremium = useCallback((page, activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC ${page} ${activityID} View Premium Data Click` : `EC ${page} View Premium Data Click`
    })
  }, [])

  const handleGAEventPurchaseModalOpen = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Purchase Modal Displayed` : `EC Premium Insights Purchase Modal Displayed`
    })
  }, [])

  const handleECGAEventPurchaseModalPurchase = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Purchase Modal Purchase Click` : `EC Premium Insights Purchase Modal Purchase Click`
    })
  }, [])

  const handleGAEventPurchaseModalClose = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Purchase Modal Close Click` : `EC Premium Insights Purchase Modal Close Click`
    })
  }, [])

  const handleGAEventCheckoutModalClose = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Checkout Modal Close Click` : `EC Premium Insights Checkout Modal Close Click`
    })
  }, [])

  const handleGAEventPostTransactionModalClose = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Post-Transaction Modal Close Click` : `EC Premium Insights Post-Transaction Modal Close Click`
    })
  }, [])

  const handleECGAEventPurchasePreviewOpen = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Purchase Modal Preview Click` : `EC Premium Insights Purchase Modal Preview Click`
    })
  }, [])

  const handleECGAEventPurchaseComplete = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.confirmation,
      label: activityID != null ? `EC Premium Insights ${activityID} Purchase Complete` : `EC Premium Insights Purchase Complete`
    })
  }, [])

  const handleGAEventClickPayButtonCheckout = useCallback((activityID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: activityID != null ? `EC Premium Insights ${activityID} Checkout Modal Pay Button Click` : `EC Premium Insights Checkout Modal Pay Button Click`
    })
  }, [])

  const handleGAEventTabClick = useCallback((activityID, tabName) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: activityID != null ? `EC Details ${activityID} ${tabName} Tab Click` : `EC Details ${tabName} Tab Click`
    })
  }, [])


  return {
    handleGAEventSearchSave,
    handleGAEventDetailsSave,
    handleGAEventExtLink,
    handleGAEventViewPremium,
    handleGAEventPurchaseModalOpen,
    handleECGAEventPurchaseModalPurchase,
    handleGAEventPurchaseModalClose,
    handleGAEventCheckoutModalClose,
    handleGAEventPostTransactionModalClose,
    handleECGAEventPurchasePreviewOpen,
    handleECGAEventPurchaseComplete,
    handleGAEventClickPayButtonCheckout,
    handleGAEventTabClick
  }
}

export const UserProfileReactGAHandlers = () => {

  const handleGAEventTabClick = useCallback((tab) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: `User Profile ${tab} Tab Click`
    })
  }, [])

  return {
    handleGAEventTabClick
  }
}

export const TestScoreStrategyReactGAHandlers = () => {

  const handleGAEventTabClick = useCallback((collegeID, tabName) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: collegeID != null ? `Test Strategy ${collegeID} Overlay ${tabName} Tab Click` : `Test Strategy Overlay ${tabName} Tab Click`
    })
  }, [])

  const handleGAEventOverlayButton = useCallback((button) => {

    ReactGA.event({
      category: categories.overlay,
      action: actions.click,
      label: `Test Strategy Edit Score Overlay ${button} Button Click`
    })
  }, [])

  const handleGAEventOpenOverlay = useCallback((collegeID) => {

    ReactGA.event({
      category: categories.overlay,
      action: actions.click,
      label: `Test Strategy ${collegeID} Open Overlay Click`
    })
  }, [])

  // const handleGAEventSave = useCallback((collegeID) => {

  //   ReactGA.event({
  //     category: categories.tab,
  //     action: actions.click,
  //     label: collegeID != null ? `Test Strategy ${collegeID} Save Button Click` : `Test Strategy Save Button Click`
  //   })
  // }, [])

  const handleGAEventEditScoreProfile = useCallback((loc) => {

    ReactGA.event({
      category: categories.filter,
      action: actions.click,
      label: loc == 'aside' ? `Test Strategy Edit Score Profile Button Click` : `Test Strategy Enter Score Button Click`
    })
  }, [])

  return {
    handleGAEventTabClick,
    handleGAEventOverlayButton,
    handleGAEventOpenOverlay,
    handleGAEventEditScoreProfile
  }
}

export const AuthReactGAHandlers = () => {

  const handleGAEventSignInModalOpen = useCallback((reason = '') => {

    let text = ""

    switch (true) {
      case reason == '':
        text = "Sign-in Modal Sign In Button Click"
        break;
      case reason == 'request':
        text = "Sign-in Modal Display Initiated"
        break;
      case reason == 'forced':
        text = "Sign-in Modal Display Forced"
        break;
    }

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: text
    })
  }, [])

  const handleGAEventNotAMemberYet = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: `Sign-in Modal Switch To Sign-Up`
    })
  }, [])

  const handleGAEventSuccessfulLogin = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.confirmation,
      label: `Sign-in Completed`
    })
  }, [])

  const handleGAEventSignUpModalOpen = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: `Sign-up Modal Sign Up Button Click`
    })
  }, [])

  const handleGAEventAlreadyHaveAccount = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: `Sign-up Modal Switch To Sign-In`
    })
  }, [])

  const handleGAEventSuccessfulRegistration = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.confirmation,
      label: `Sign-up Completed`
    })
  }, [])

  const handleEmailVerificationResendRequest = useCallback(() => {

    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Email verification resend request`
    })
  }, [])

  const handleGoogleSignIn = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: `Sign-in Modal Google Sign-in`
    })
  }, [])

  const handleGoogleSignUp = useCallback(() => {

    ReactGA.event({
      category: categories.auth,
      action: actions.click,
      label: `Sign-up Modal Google Sign-in`
    })
  }, [])


  return {
    handleGAEventSignInModalOpen,
    handleGAEventSignUpModalOpen,
    handleGAEventNotAMemberYet,
    handleGAEventAlreadyHaveAccount,
    handleGAEventSuccessfulLogin,
    handleGAEventSuccessfulRegistration,
    handleEmailVerificationResendRequest,
    handleGoogleSignIn,
    handleGoogleSignUp
  }
}

export const AdvisorsReactGAHandlers = () => {

  const handleGAEventPurchaseModalOpen = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: advisorID ? `Advisor ID: ${advisorID} Purchase Modal Displayed` : `Advisors Purchase Modal Displayed`
    })
  }, [])

  const handleAdvisorGAEventPurchaseComplete = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.confirmation,
      label: `Advisor (${advisorID}) Connect Purchased`
    })
  }, [])

  const handleAdvisorGAEventCardConnectClick = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Advisors Search ${advisorID} Connect Click`
    })
  }, [])

  const handleAdvisorGAEventDetailConnectClick = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Advisors Profile ${advisorID} Connect Click`
    })
  }, [])

  const handleAdvisorGAEventConsultPurchaseButtonClick = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Advisors Consultation ${advisorID} Purchase Modal Purchase Click`
    })
  }, [])

  const handleAdvisorGAEventConsultClose = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Advisors Consultation ${advisorID} Purchase Modal Close Click`
    })
  }, [])

  const handleAdvisorGAEventConsultCheckoutClose = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.purchase,
      action: actions.click,
      label: `Advisors Consultation ${advisorID} Checkout Modal Close Click`
    })
  }, [])

  const handleAdvisorGAEventSelectProfileInfo = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: `Advisor Profile ${advisorID} Profile Info Tab Click`
    })
  }, [])

  const handleAdvisorGAEventSelectQualifications = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: `Advisor Profile ${advisorID} Qualifications Tab Click`
    })
  }, [])

  const handleAdvisorGAEventSelectServices = useCallback((advisorID) => {
    ReactGA.event({
      category: categories.tab,
      action: actions.click,
      label: `Advisor Profile ${advisorID} Services Tab Click`
    })
  }, [])

  const handleAdvisorGAEventCollegeFilter = useCallback(() => {
    ReactGA.event({
      category: categories.filter,
      action: actions.click,
      label: `Advisor Search Filter College Click`
    })
  }, [])

  const handleAdvisorGAEventFieldOfStudyFilter = useCallback(() => {
    ReactGA.event({
      category: categories.filter,
      action: actions.click,
      label: `Advisor Search Filter Field Click`
    })
  }, [])

  const handleAdvisorGAEventECFilter = useCallback(() => {
    ReactGA.event({
      category: categories.filter,
      action: actions.click,
      label: `Advisor Search Filter EC Click`
    })
  }, [])


  return {
    handleGAEventPurchaseModalOpen,
    handleAdvisorGAEventPurchaseComplete,
    handleAdvisorGAEventCardConnectClick,
    handleAdvisorGAEventDetailConnectClick,
    handleAdvisorGAEventConsultPurchaseButtonClick,
    handleAdvisorGAEventConsultClose,
    handleAdvisorGAEventConsultCheckoutClose,
    handleAdvisorGAEventSelectProfileInfo,
    handleAdvisorGAEventSelectQualifications,
    handleAdvisorGAEventSelectServices,
    handleAdvisorGAEventCollegeFilter,
    handleAdvisorGAEventFieldOfStudyFilter,
    handleAdvisorGAEventECFilter
  }

}


