import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CollegeSearchAside } from '../components/CollegeSearch/CollegeSearchAside'
import { CollegeCard } from '../components/CollegeSearch/CollegeCard'
import { useAuth, getAuth } from '../modules/auth'
import { concatLocation, returnParameterString } from '../components/searchPageURLManager'
import { LoadingIndicator } from '../components/LoadingIndicator'

const sortKeyOptions = { 'Metarank': 'metaRank', 'Median Earnings': 'median4yrEarnings', 'Undergrads': 'numUndergrads', 'Median SAT': 'satComposite50', 'Median ACT': 'actComposite50' }

const CollegeSearchPage = ({ user, userCollegeInterest, setUserCollegeInterest, collegesId, setPrompt, setNavigateTo, collegeSearchLocation, setCollegeSearchLocation }) => {

  const location = useLocation()
  const navigate = useNavigate()

  // initialize variable to return in component(s)
  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { currentUser, auth } = useAuth()

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }
  }, [user])

  //////// useStates //////// 
  // Full college result array
  const [colleges, setColleges] = useState<any>([])

  // Full array of filtered colleges
  const [filteredCollegesFull, setFilteredCollegesFull] = useState<any>([])

  // Filtered college array, after splice
  const [filteredColleges, setFilteredColleges] = useState<any>([])

  // Max items to display as results
  const [showLength, setShowLength] = useState(25)

  // Colleges filtered state, false when sort and filter in progress or filters return no results, true when complete
  const [filteredResults, setFilteredResults] = useState<any>(false)

  const [selectorNameOptions, setSelectorNameOptions] = useState<any>([])
  const [selectorLocationOptions, setSelectorLocationOptions] = useState<any>([])
  const [sortKey, setSortKey] = useState<any>('metaRank')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')
  const [earlyApplicationOfferedCount, setEarlyAppOptionCount] = useState<any>(0)
  const [commonAppCount, setCommonAppCount] = useState<any>(0)
  const [coalitionCount, setCoalitionCount] = useState<any>(0)
  const [individualAppCount, setIndividualAppCount] = useState<any>(0)
  const [considersInterestCount, setConsidersInterestCount] = useState<any>(0)

  // Show only interests states
  // State of checkbox
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  // State to pass to sort and filter, if true override all card results with a message
  const [guestClickShowOnlyInterest, setGuestClickShowOnlyInterest] = useState(false)

  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorCollegeIDs, setAdvisorCollegeIDs] = useState<any>([])

  const [showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter] = useState<any>((item) => {
    return item => item
  })

  useEffect(() => {

    const returnShowOnlyInterestFilter = (onlyInterestsSelect) => {

      if (onlyInterestsSelect === true && userCollegeInterest && userCollegeInterest.length > 0) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => userCollegeInterest.some((obj) => obj.college_id == item.id
          )
        }
        )
      }

      else if (onlyInterestsSelect === true && (!userCollegeInterest || (userCollegeInterest && !(userCollegeInterest.length > 0)))) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => false
        })
      }

      else {
        setShowOnlyMyInterestsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyInterestFilter(onlyInterestsSelect)

  }, [onlyInterestsSelect])

  //////// End of useStates //////// 

  const handleSortChange = () => {
    sortOrder === 'Ascending' ? setSortOrder('Descending') : setSortOrder('Ascending')
  }

  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    setSortKey(selectedKey)
  }

  // States for filter values
  const [nameFilter, setNameFilter] = useState<any>([])
  const [institutionTypeFilter, setInstitutionTypeFilter] = useState(0)
  const [locationFilter, setLocationFilter] = useState<any>([])
  const [settingFilter, setSettingFilter] = useState<any>('')
  const [sizeFilter, setSizeFilter] = useState(0)
  const [selectivityMin, setSelectivityMin] = useState<any>(0)
  const [selectivityMax, setSelectivityMax] = useState<any>(100)
  const [testPolicyFilter, setTestPolicyFilter] = useState<any>('')
  const [earlyAppOptionFilter, setEarlyAppOptionFilter] = useState<any>(null)
  const [commonAppFilter, setCommonAppFilter] = useState<any>(null)
  const [coalitionFilter, setCoalitionFilter] = useState<any>(null)
  const [individualAppFilter, setIndividualAppFilter] = useState<any>(null)
  const [considersInterestFilter, setConsidersInterestFilter] = useState<any>(null)

  // States to store filter logic based on the filter values
  const [filteredNames, setFilteredNames] = useState<any>((item) => {
    return item => item
  })
  const [filteredType, setFilteredType] = useState<any>((item) => {
    return item => item
  })
  const [filteredLocations, setFilteredLocations] = useState<any>((item) => {
    return item => item
  })
  const [filteredSetting, setFilteredSetting] = useState<any>((item) => {
    return item => item
  })
  const [filteredSize, setFilteredSize] = useState<any>((item) => {
    return item => item
  })
  const [filteredSelectivity, setFilteredSelectivity] = useState<any>((item) => {
    return item => item
  })
  const [filteredTestPolicy, setFilteredTestPolicy] = useState<any>((item) => {
    return item => item
  })
  const [filteredEarlyAppOptions, setFilteredEarlyAppOptions] = useState<any>((item) => {
    return item => item
  })
  const [filteredCommonApp, setFilteredCommonApp] = useState<any>((item) => {
    return item => item
  })
  const [filteredCoalition, setFilteredCoalition] = useState<any>((item) => {
    return item => item
  })
  const [filteredIndividualApp, setFilteredIndividualApp] = useState<any>((item) => {
    return item => item
  })
  const [filteredConsidersInterest, setFilteredConsidersInterest] = useState<any>((item) => { return item => item })

  //////// useEffects //////// 
  useEffect(() => {
    dictionary.updateTitleAndDescription(dictionary.pageRoutes.collegeSearch)

    const getData = async () => {
      const handleSetAdvisors = async () => {
        let advisorCache: string | null = localStorage.getItem('dantech-advisors')

        if (!advisorCache) {
          // axios request to get advisors
          let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
          let advisorColleges: any = []
          res.data.map(advisor => {
            if (advisor.college_id) {
              advisorColleges.push(parseInt(advisor.college_id))
            }
          })

          try {
            localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
          } catch (error) {
            console.log('Error:', error)
          }

          setAdvisorData(res.data)
          setAdvisorCollegeIDs([...new Set(advisorColleges)])

        } else {
          try {

            const advisorDataParsed = JSON.parse(advisorCache)
            setAdvisorData(advisorDataParsed)

            let advisorColleges: any = []
            advisorDataParsed.map(advisor => {
              if (advisor.college_id) {
                advisorColleges.push(parseInt(advisor.college_id))
              }
            })
            setAdvisorCollegeIDs([...new Set(advisorColleges)])

          } catch (error) {

            console.log('Error grabbing data, using axios instead:', error)
            let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
            let advisorColleges: any = []
            res.data.map(advisor => {
              if (advisor.college_id) {
                advisorColleges.push(parseInt(advisor.college_id))
              }
            })

            try {
              localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
            } catch (error) {
              console.log('Error:', error)
            }

            setAdvisorData(res.data)
            setAdvisorCollegeIDs([...new Set(advisorColleges)])

          }
        }
      }

      await handleSetAdvisors()
    }

    getData()

  }, [])

  useEffect(() => {
    const getColleges = async () => {

      let collegeCache = localStorage.getItem('dantech-colleges');

      const mapAndSet = (collegesRes) => {
        let nameOps = collegesRes.map((college) => ({
          value: college.name,
          label: college.name,
        }));
        nameOps.sort((a, b) => a.value.localeCompare(b.value));
        setSelectorNameOptions(nameOps);
      };

      const noCacheRequestCollegeData = async () => {
        try {
          // Get colleges
          let res = await axios.get(`${dictionary.URL}/colleges/${0}-to-${collegesId.length}?sortKey=${sortKey}`)

          // If res.data is NOT an empty array--
          if (res.data && res.data.length > 0) {
            setColleges(res.data);

            // Attempt to store via localStorage
            try {
              localStorage.setItem('dantech-colleges', JSON.stringify(res.data))
              console.log('set item dantech colleges')
            } catch (error) {
              console.log('Error, no collegeCache attempt to store dantech-colleges in localStorage:', error)
            }

            // Set names using the college data retrieved above
            // let nameOps: any[] = [];
            // res.data.map((college) => {
            //   nameOps.push({ value: college.name, label: college.name })
            // })
            // nameOps.sort((a, b) => a.value.localeCompare(b.value))
            // setSelectorNameOptions(nameOps)
            mapAndSet(res.data)
          }

          // Set location options in aside selector using the locationOptions from dictionary file
          setSelectorLocationOptions(dictionary.locationOptions)
        } catch (error) {
          console.log('Error getting college data from axios request.', error)
        }
      }

      if (!collegeCache) {
        await noCacheRequestCollegeData()
      }
      // else if (collegeCache && Array.isArray(JSON.parse(collegeCache)) && JSON.parse(collegeCache).length > 0) {
      else {
        try {

          const collegeCacheParsed = JSON.parse(collegeCache)

          if (collegeCacheParsed && collegeCacheParsed.length > 0) {
            setColleges(collegeCacheParsed)
            // let nameOps: any[] = [];
            // collegeCacheParsed.map((college) => {
            //   nameOps.push({ value: college.name, label: college.name })
            // })

            // nameOps.sort((a, b) => a.value.localeCompare(b.value))

            // setSelectorNameOptions(nameOps)
            mapAndSet(collegeCacheParsed)
            setSelectorLocationOptions(dictionary.locationOptions)
          } else if (collegeCacheParsed && collegeCacheParsed.length == 0) {
            console.log('College cache is present but an empty array, update data...')
            localStorage.removeItem('dantech-colleges');
            await noCacheRequestCollegeData()
          } else {
            console.log('Error setting state using college cache. Requesting college data...')
            await noCacheRequestCollegeData()
          }



        } catch (error) {
          // console.log('Error grabbing colleges from cache', error)

          // // Get colleges
          // let res = await axios.get(`${dictionary.URL}/colleges/${0}-to-${collegesId.length}?sortKey=${sortKey}`)
          // setColleges(res.data)

          // // Attempt to store via localStorage
          // try {
          //   localStorage.setItem('dantech-colleges', JSON.stringify(res.data))
          // } catch (error) {
          //   console.log('Error, collegeCache available, attempt to store dantech-colleges in localStorage:', error)
          // }

          // // Set names using the college data retrieved above
          // let nameOps: any[] = [];
          // res.data.map((college) => {
          //   nameOps.push({ value: college.name, label: college.name })
          // })
          // nameOps.sort((a, b) => a.value.localeCompare(b.value))
          // setSelectorNameOptions(nameOps)

          // // Set location options in aside selector using the locationOptions from dictionary file
          // setSelectorLocationOptions(dictionary.locationOptions)
          console.log('Error getting college data from axios request.', error)
        }
      }
    }
    getColleges();

    // add collegesId to dependency array for scenarios like page reload or route from outside source where collegesId state may not be updated yet for the axios request
  }, [collegesId])

  //// Filter triggers to update state of filter logic variables ////
  // nameFilter Trigger
  useEffect(() => {
    const filterCollegesByName = (nameFilter) => {
      //nameFilter is an array of strings
      if (nameFilter.length !== 0) {
        setFilteredNames((item) => {
          // Return one result
          // return item => item.name.includes(nameFilter)

          // Switch back to return each selected name if present
          return item => nameFilter.includes(item.name)
        })
      }
      else {
        setFilteredNames((item) => {
          return item => item
        })
      }
    };
    filterCollegesByName(nameFilter)
  }, [nameFilter])

  // typeFilter Trigger
  useEffect(() => {

    const filterCollegesByType = (institutionTypeFilter) => {
      switch (institutionTypeFilter) {
        case 0:
          setFilteredType((item) => {
            return item => item
          })
          break;

        case 1:
          setFilteredType((item) => {
            return item => item.type === 1
          })
          break;

        case 2:
          setFilteredType((item) => {
            return item => [2, 3].includes(item.type)
          })
          break;

        default:
          setFilteredType((item) => {
            return item => item
          })
      };
    };

    filterCollegesByType(institutionTypeFilter)

  }, [institutionTypeFilter])

  ///Need to change locationFilter value ? locationState is an int, if value is the string there wont be a match
  // locationFilter Trigger
  useEffect(() => {

    const filterCollegesByLocation = (locationFilter) => {
      if (locationFilter.length !== 0) {
        setFilteredLocations((item) => {
          return item => locationFilter.includes(item.locationState)
        })
      }
      else {
        setFilteredLocations((item) => {
          return item => item
        })
      }
    };
    filterCollegesByLocation(locationFilter)

  }, [locationFilter])

  // settingFilter Trigger
  useEffect(() => {

    const filterCollegesBySetting = (settingFilter) => {
      if (settingFilter !== '') {
        setFilteredSetting((item) => {
          return item => dictionary.setting[settingFilter].includes(item.setting)
        })
      }

      else {
        setFilteredSetting((item) => {
          return item => item
        })
      };
    }
    filterCollegesBySetting(settingFilter)
  }, [settingFilter])

  // sizeFilter Trigger
  useEffect(() => {

    const filterCollegesBySize = (sizeFilter) => {
      if (sizeFilter > 0) {
        setFilteredSize((item) => {
          return item => item.size === sizeFilter
        })
      }

      else {
        setFilteredSize((item) => {
          return item => item
        })
      };
    }
    filterCollegesBySize(sizeFilter)

  }, [sizeFilter])

  // selectivityFilter Trigger
  useEffect(() => {

    const filterCollegesBySelectivity = (selectivityMin, selectivityMax) => {

      if (selectivityMin !== 0) {
        setFilteredSelectivity((item) => {
          return item => ((item.college_cds.length > 0) && ((item.college_cds[0].totalAdmitRate * 100) >= selectivityMin && (item.college_cds[0].totalAdmitRate * 100) <= selectivityMax))
        })
      }

      else if (selectivityMax !== 100) {
        setFilteredSelectivity((item) => {
          return item => ((item.college_cds.length > 0) && ((item.college_cds[0].totalAdmitRate * 100) >= selectivityMin && (item.college_cds[0].totalAdmitRate * 100) <= selectivityMax))
        })
      }

      else {
        setFilteredSelectivity((item) => {
          return item => item
        })
      }

    };
    filterCollegesBySelectivity(selectivityMin, selectivityMax)

  }, [selectivityMin, selectivityMax])

  // testPolicyFilter Trigger
  useEffect(() => {

    const filterCollegesByTestPolicy = (testPolicyFilter) => {
      if (testPolicyFilter > 0) {
        setFilteredTestPolicy((item) => {
          return item => item.testPolicy === testPolicyFilter
        })
      }

      else {
        setFilteredTestPolicy((item) => {
          return item => item
        })
      };
    }
    filterCollegesByTestPolicy(testPolicyFilter)

  }, [testPolicyFilter])

  // appDetails Trigger
  useEffect(() => {
    const filterAppOptions = (earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter) => {

      switch (earlyAppOptionFilter) {
        case true:
          setFilteredEarlyAppOptions((item) => {
            return item => item.earlyApplicationOffered === true
          })
          break;
        default:
          setFilteredEarlyAppOptions((item) => {
            return item => item
          })
      };

      switch (commonAppFilter) {
        case true:
          setFilteredCommonApp((item) => {
            return item => item.commonApp === true
          })
          break;
        default:
          setFilteredCommonApp((item) => {
            return item => item
          })
      };

      switch (coalitionFilter) {
        case true:
          setFilteredCoalition((item) => {
            return item => item.coalitionApp === true
          })
          break;
        default:
          setFilteredCoalition((item) => {
            return item => item
          })
      };

      switch (individualAppFilter) {
        case true:
          setFilteredIndividualApp((item) => {
            return item => item.individualApp === true
          })
          break;
        default:
          setFilteredIndividualApp((item) => {
            return item => item
          })
      };

      switch (considersInterestFilter) {
        case true:
          setFilteredConsidersInterest((item) => {
            return item => item.considersInterest === true
          })
          break;
        default:
          setFilteredConsidersInterest((item) => {
            return item => item
          })
      };
    };
    filterAppOptions(earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter)
  }, [earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter])

  //////// Apply all filters once filter logic has changed ////////
  useEffect(() => {
    const applyFilters = (filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortKey, sortOrder, showOnlyMyInterestsFilter) => {

      if (onlyInterestsSelect == true && (!currentUser || !user)) {
        return setGuestClickShowOnlyInterest(true)
      }

      else {
        setGuestClickShowOnlyInterest(false)
      }

      let res: any[] = colleges

      res = res.filter(filteredNames).filter(filteredType).filter(filteredLocations).filter(filteredSetting).filter(filteredSize).filter(filteredTestPolicy).filter(filteredEarlyAppOptions).filter(filteredCommonApp).filter(filteredCoalition).filter(filteredIndividualApp).filter(filteredConsidersInterest).filter(filteredSelectivity).filter(showOnlyMyInterestsFilter)

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      // Before splice, set full to res
      setFilteredCollegesFull(res)

      switch (true) {
        case sortKey === 'satComposite50':
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => dictionary.sortLogic(b.college_cds[0], a.college_cds[0], 'satComposite50', 'name')) :
            res = res.sort((a, b) => dictionary.sortLogic(a.college_cds[0], b.college_cds[0], 'satComposite50', 'name'))
          break;
        case sortKey === 'actComposite50':
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => dictionary.sortLogic(b.college_cds[0], a.college_cds[0], 'actComposite50', 'name')) :
            res = res.sort((a, b) => dictionary.sortLogic(a.college_cds[0], b.college_cds[0], 'actComposite50', 'name'))
          break;
        default:
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
            res = res.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))
      }

      let earlyCount = res.filter((item) => item.earlyApplicationOffered === true).length
      let commonCount = res.filter((item) => item.commonApp === true).length
      let coalitionCount = res.filter((item) => item.coalitionApp === true).length
      let individualCount = res.filter((item) => item.individualApp === true).length
      let considersInterest = res.filter((item) => item.considersInterest === true).length

      setEarlyAppOptionCount(earlyCount)
      setCommonAppCount(commonCount)
      setCoalitionCount(coalitionCount)
      setIndividualAppCount(individualCount)
      setConsidersInterestCount(considersInterest)

      ////// Set filtered colleges 
      // Create copy of res using slice and set to new variable
      // Splice on the copy by the amount of showLength
      // Then set state of filtered colleges for cards component
      let splicedRes = res.slice()
      splicedRes = splicedRes.splice(0, showLength)
      setFilteredColleges(splicedRes)

      // let nameOps: any[] = [];
      // res.map((college) => {
      //   nameOps.push({ value: college.name, label: college.name })
      // })

      // nameOps.sort((a, b) => a.value.localeCompare(b.value))

      // setSelectorNameOptions(nameOps)
      // setSelectorLocationOptions(dictionary.locationOptions)

      if (filteredResults === false) {
        setFilteredResults(true)
      }

      if (guestClickShowOnlyInterest == true) {
        setGuestClickShowOnlyInterest(false)
      }

    };

    applyFilters(filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortKey, sortOrder, showOnlyMyInterestsFilter);

  }, [filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortOrder, sortKey, colleges, showLength, showOnlyMyInterestsFilter])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');
    let showParam = params.get('show')

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

    if (showParam) {
      setShowLength(parseInt(showParam))
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('show', `${showLength}`)

    setShowLengthParams(`${params.toString()}`)

  }, [showLength])

  //// URL and filter store states
  const [showLengthParams, setShowLengthParams] = useState('')
  const [sortParams, setSortParams] = useState('')
  const [nameParams, setNameParams] = useState('')
  const [typeParams, setTypeParams] = useState('')
  const [locationParams, setLocationParams] = useState('')
  const [settingParams, setSettingParams] = useState('')
  const [sizeParams, setSizeParams] = useState('')
  const [admitParams, setAdmitParams] = useState('')
  const [testPolicyParams, setTestPolicyParams] = useState('')
  const [applicationDetailsParams, setApplicationDetailsParams] = useState('')
  const [onlyInterestsParams, setOnlyInterestsParams] = useState('')

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []
    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(onlyInterestsParams, 'onlyInterests'),
      returnParameterString(showLengthParams, 'show'),
      returnParameterString(sortParams, 'sort'),
      returnParameterString(nameParams, 'name'),
      returnParameterString(typeParams, 'type'),
      returnParameterString(locationParams, 'location'),
      returnParameterString(settingParams, 'setting'),
      returnParameterString(sizeParams, 'size'),
      returnParameterString(admitParams, 'admitRate'),
      returnParameterString(testPolicyParams, 'testPolicy'),
      returnParameterString(applicationDetailsParams, 'appDetails')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setCollegeSearchLocation(`${dictionary.pageRoutes.collegeSearch}${concatLocation(params)}`)
  }, [nameParams, typeParams, locationParams, settingParams, sizeParams, admitParams, testPolicyParams, applicationDetailsParams, sortParams, showLengthParams, onlyInterestsParams])

  useEffect(() => {

    window.history.replaceState({}, '', collegeSearchLocation)

  }, [collegeSearchLocation])

  //////// Components to render ////////
  const collegeSearchHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Colleges</span>

        <div className="dca-search-header-bttn-container-double">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('creating-a-college-interest-list'))}
            className='dca-search-header-bttn'
          >Advice: Building A College List</button>

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.collegeDataVisualization)}
            className='dca-search-header-bttn'
            style={{ marginLeft: '8px' }}
          >Admission Data Scattergrams</button>

        </div>
      </div>

      <span className="dca-search-description">Find the top-ranked colleges that fit your personal preferences and academic profile. Dive into data on admission statistics, current student benchmarks, and proprietary rankings to help build a balanced interest list.</span>

    </div>
  )

  const returnResults = (filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges) => {
    switch (true) {
      case (onlyInterestsSelect === true && colleges.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>Please save colleges to your interest list in order for them to appear here.</div>
        </div>
      case guestClickShowOnlyInterest == true:
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>Please first log-in to your account in order for your saved colleges to appear here.</div>
        </div>
      case filteredResults == true:
        return <CollegeCard
          user={user}
          userCollegeInterest={userCollegeInterest}
          colleges={colleges}
          setColleges={setColleges}
          collegeListLength={collegesId.length}
          filteredColleges={filteredColleges}
          setFilteredColleges={setFilteredColleges}
          setUserCollegeInterest={setUserCollegeInterest}
          sortKeyOptions={sortKeyOptions}
          handleSortKeyChange={handleSortKeyChange}
          handleSortChange={handleSortChange}
          showLength={showLength}
          setShowLength={setShowLength}
          filteredCollegesFull={filteredCollegesFull}

          filteredNames={filteredNames}
          filteredType={filteredType}
          filteredLocations={filteredLocations}
          filteredSetting={filteredSetting}
          filteredSize={filteredSize}
          filteredTestPolicy={filteredTestPolicy}
          filteredEarlyAppOptions={filteredEarlyAppOptions}
          filteredCommonApp={filteredCommonApp}
          filteredCoalition={filteredCoalition}
          filteredIndividualApp={filteredIndividualApp}
          filteredConsidersInterest={filteredConsidersInterest}
          filteredSelectivity={filteredSelectivity}
          setFilteredResults={setFilteredResults}
          setSelectorNameOptions={setSelectorNameOptions}
          sortKey={sortKey}
          sortOrder={sortOrder}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}

          advisorCollegeIDs={advisorCollegeIDs}
        />
      case filteredResults != true:
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>No results meet your criteria</div>
        </div>
    }
  }

  return colleges?.length > 0 ? (
    <div>
      {collegeSearchHeader}

      <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
        <CollegeSearchAside
          filteredColleges={filteredColleges}
          selectorNameOptions={selectorNameOptions}
          selectorLocationOptions={selectorLocationOptions}
          setNameFilter={setNameFilter}
          institutionTypeFilter={institutionTypeFilter}
          setInstitutionTypeFilter={setInstitutionTypeFilter}
          setLocationFilter={setLocationFilter}
          setSettingFilter={setSettingFilter}
          setSizeFilter={setSizeFilter}
          selectivityMin={selectivityMin}
          selectivityMax={selectivityMax}
          setSelectivityMin={setSelectivityMin}
          setSelectivityMax={setSelectivityMax}
          setTestPolicyFilter={setTestPolicyFilter}
          setEarlyAppOptionFilter={setEarlyAppOptionFilter}
          setCommonAppFilter={setCommonAppFilter}
          setCoalitionFilter={setCoalitionFilter}
          setIndividualAppFilter={setIndividualAppFilter}
          setConsidersInterestFilter={setConsidersInterestFilter}
          earlyApplicationOfferedCount={earlyApplicationOfferedCount}
          commonAppCount={commonAppCount}
          coalitionCount={coalitionCount}
          individualAppCount={individualAppCount}
          considersInterestCount={considersInterestCount}
          collegeSearchLocation={collegeSearchLocation}
          setCollegeSearchLocation={setCollegeSearchLocation}
          earlyAppOptionFilter={earlyAppOptionFilter}
          commonAppFilter={commonAppFilter}
          coalitionFilter={coalitionFilter}
          individualAppFilter={individualAppFilter}
          considersInterestFilter={considersInterestFilter}
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}

          setNameParams={setNameParams}
          setTypeParams={setTypeParams}
          setLocationParams={setLocationParams}
          setSettingParams={setSettingParams}
          setSizeParams={setSizeParams}
          setAdmitParams={setAdmitParams}
          setTestPolicyParams={setTestPolicyParams}
          setApplicationDetailsParams={setApplicationDetailsParams}
          setOnlyInterestsParams={setOnlyInterestsParams}
        />
        {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges)}
      </div>
    </div>
  ) : (
    <div>
      <div>
        {collegeSearchHeader}
        <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
          <CollegeSearchAside
            filteredColleges={filteredColleges}
            selectorNameOptions={selectorNameOptions}
            selectorLocationOptions={selectorLocationOptions}
            setNameFilter={setNameFilter}
            institutionTypeFilter={institutionTypeFilter}
            setInstitutionTypeFilter={setInstitutionTypeFilter}
            setLocationFilter={setLocationFilter}
            setSettingFilter={setSettingFilter}
            setSizeFilter={setSizeFilter}
            setTestPolicyFilter={setTestPolicyFilter}
            setEarlyAppOptionFilter={setEarlyAppOptionFilter}
            setCommonAppFilter={setCommonAppFilter}
            setCoalitionFilter={setCoalitionFilter}
            selectivityMin={selectivityMin}
            selectivityMax={selectivityMax}
            setSelectivityMin={setSelectivityMin}
            setSelectivityMax={setSelectivityMax}
            setIndividualAppFilter={setIndividualAppFilter}
            setConsidersInterestFilter={setConsidersInterestFilter}
            earlyApplicationOfferedCount={earlyApplicationOfferedCount}
            commonAppCount={commonAppCount}
            coalitionCount={coalitionCount}
            individualAppCount={individualAppCount}
            considersInterestCount={considersInterestCount}
            collegeSearchLocation={collegeSearchLocation}
            setCollegeSearchLocation={setCollegeSearchLocation}
            earlyAppOptionFilter={earlyAppOptionFilter}
            commonAppFilter={commonAppFilter}
            coalitionFilter={coalitionFilter}
            individualAppFilter={individualAppFilter}
            considersInterestFilter={considersInterestFilter}
            onlyInterestsSelect={onlyInterestsSelect}
            setOnlyInterestsSelect={setOnlyInterestsSelect}

            setNameParams={setNameParams}
            setTypeParams={setTypeParams}
            setLocationParams={setLocationParams}
            setSettingParams={setSettingParams}
            setSizeParams={setSizeParams}
            setAdmitParams={setAdmitParams}
            setTestPolicyParams={setTestPolicyParams}
            setApplicationDetailsParams={setApplicationDetailsParams}
            setOnlyInterestsParams={setOnlyInterestsParams}
          />
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
            <LoadingIndicator text={'Loading Information On Colleges...'} />
          </div>
        </div>
      </div>
    </div>
  )

}

export { CollegeSearchPage }
