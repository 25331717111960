import { FC, useState, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { App } from '../App'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, useAuth, getAuth } from '../modules/auth'
import { ScrollToTop } from './ScrollToTop'
import { URL, pageRoutes } from '../components/dictionary'
import axios from 'axios'

// Public Route Pages
import { Home } from '../pages/Home'
import { CollegeSearchPage } from '../pages/CollegeSearchPage'
import { ECSearchPage } from '../pages/ECSearchPage'
import { MajorSearchPage } from '../pages/MajorSearchPage'
import { ECPremiumInsights } from '../pages/ECPremiumInsights'
import { AdvisorSearchPage } from '../pages/AdvisorSearchPage'
import { AdvisorDetailPage } from '../pages/AdvisorDetail'
import { MarketingPage } from '../pages/MarketingPage'
import { SignUpPage } from '../pages/Auth/SignUpPage'
import { SignInPage } from '../pages/Auth/SignInPage'
import { OverviewPage } from '../pages/OverviewPage'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'
import { TermsOfService } from '../pages/TermsOfService'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

// Private Route Pages
import { TestScoreStrategy } from '../pages/TestScoreStrategy'
import { CollegeDataVisualization } from '../pages/CollegeDataVisualization'
import { UserProfile } from '../pages/UserProfile'
import { UpgradeTierPage } from '../pages/Auth/UpgradeTierPage'

// Modals
import { SignInModal } from '../components/Auth/SignInModal'
import { SignUpModal } from '../components/Auth/SignUpModal'
import { ValidateEmailModal } from '../components/Auth/ValidateEmailModal'
import { UpgradeAccountModal } from '../components/Auth/UpgradeAccountModal'
import { AddCardModal } from '../components/Payments/AddCardModal'
import { PurchaseDescriptionModal } from '../components/Payments/PurchaseDescriptionModal'
import { PremiumPreviewModal } from '../components/Payments/PremiumPreviewModal'
import { About } from '../pages/About'
import { FAQ } from '../pages/FAQ'
import { Contact } from '../pages/Contact'
import { ConditionalRouter } from '../pages/ConditionalRouter'
import { ScreenWidthAlertModal } from '../components/ScreenWidthAlertModal'
import { EmailVerificationRequestModal } from '../components/Auth/EmailVerificationRequestModal'

// import { CollegeDetailPage } from '../pages/CollegeDetailPage'
// import { ECDetailPage } from '../pages/ECDetailPage'
// import { MajorDetailPage } from '../pages/MajorDetailPage'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser, setCurrentUser } = useAuth()
  const [userCollegeInterest, setUserCollegeInterest] = useState<any>([])
  const [collegesId, setCollegesID] = useState<any>([])
  const [cip, setCIP] = useState<any>([])
  const [ecActivities, setECActivities] = useState<any>([])

  // Initialize modal variables
  const [prompt, setPrompt] = useState('')
  const [navigateTo, setNavigateTo] = useState('')
  const [purchaseID, setPurchaseID] = useState('')
  const [purchaseModalCollegeName, setPurchaseModalCollegeName] = useState('')
  const [upgradeUserGroupAccessCode, setUpgradeUserGroupAccessCode] = useState('')
  const [ecID, setECID] = useState<any>(null)
  const [ecName, setECName] = useState('')
  const [ecNumAlumni, setECNumAlumni] = useState<any>(undefined)
  const [showNavAfterPayment, setShowNavAfterPayment] = useState(false)
  const [collegeDetailSelectedTab, setCollegeDetailSelectedTab] = useState('Admission Info')
  const [advisorID, setAdvisorID] = useState<any>(null)
  // Stripe embedded checkout
  const [clientSecret, setClientSecret] = useState('');
  // const [stripeStatus, setStripeStatus] = useState(null)

  // Location history states
  const [collegeSearchLocation, setCollegeSearchLocation] = useState(pageRoutes.collegeSearch)
  const [majorSearchLocation, setMajorSearchLocation] = useState(pageRoutes.majorSearch)
  const [ecSearchLocation, setECSearchLocation] = useState(pageRoutes.ecSearch)
  const [advisorSearchLocation, setAdvisorSearchLocation] = useState(pageRoutes.advisorSearch)
  const [collegeDataVisLocation, setCollegeDataVisLocation] = useState(pageRoutes.collegeDataVisualization)

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const getCollegesNameID = async () => {
    let res = await axios.get(`${URL}/colleges-id-name/`)
    console.log('Colleges Name ID Data:', res.data)
    setCollegesID(res.data)
  }

  const getCIP = async () => {
    let res = await axios.get(`${URL}/cip_taxonomy/`)
    setCIP(res.data)
  }

  const getECActivities = async () => {
    let res = await axios.get(`${URL}/ec_activity/`)
    setECActivities(res.data)
  }

  useEffect(() => {
    getCollegesNameID();
    getCIP();
    getECActivities();
  }, [])

  useEffect(() => {
    const getUserCollegeInterest = async (currentUser) => {
      let res = await axios.get(`${URL}/user_college_interest/`)
      res = res.data.filter((item) => item.user_profile_id === currentUser.user_id)
      setUserCollegeInterest(res)
    }

    if (currentUser?.emailAddress) {
      getUserCollegeInterest(currentUser)
    }
  }, [currentUser])

  // Check advisors 'boolean'
  const advisorsCheck = process.env.REACT_APP_ADVISORS_TOGGLE
  const showMarketing = process.env.REACT_APP_MARKETING_TOGGLE
  // const advisorsCheck = 'true'

  const allRoutes = (
    <>
      <Route path={pageRoutes.home} element={
        <Home
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setCurrentUser={setCurrentUser}
        />
      } />

      {/* For specific request of SEO friendly URLs on overview */}
      <Route path={pageRoutes.overviewIntroduction} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
      />} />
      <Route path={pageRoutes.overviewCollegeAppWalkthrough} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
      />} />
      <Route path={pageRoutes.overviewCollegeAppWalkthroughForRouter} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
      />} />
      <Route path={pageRoutes.overviewAdmissionsAdvice} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
      />} />
      <Route path={pageRoutes.overviewAdmissionsAdviceForRouter} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
      />} />
      {/*  */}

      <Route path={pageRoutes.conditional} element={
        <ConditionalRouter
          user={currentUser}
          setUser={setCurrentUser}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          collegeListLength={collegesId.length}
          setPurchaseID={setPurchaseID}
          setPurchaseModalCollegeName={setPurchaseModalCollegeName}
          selectedTab={collegeDetailSelectedTab}
          setSelectedTab={setCollegeDetailSelectedTab}
          collegeSearchLocation={collegeSearchLocation}
          setECName={setECName}
          setECNumAlumni={setECNumAlumni}
          setECID={setECID}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          ecName={ecName}
          ecSearchLocation={ecSearchLocation}
          majorSearchLocation={majorSearchLocation}
        />
      } />
      <Route path={pageRoutes.collegeSearch}
        element={<CollegeSearchPage
          user={currentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          collegeSearchLocation={collegeSearchLocation}
          setCollegeSearchLocation={setCollegeSearchLocation}
        />} />
      {/* <Route path={pageRoutes.collegeDetailGeneric}
        element={<CollegeDetailPage
          user={currentUser}
          setUser={setCurrentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          collegeListLength={collegesId.length}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setPurchaseModalCollegeName={setPurchaseModalCollegeName}
          selectedTab={collegeDetailSelectedTab}
          setSelectedTab={setCollegeDetailSelectedTab}
          collegeSearchLocation={collegeSearchLocation}
        />} /> */}
      {showMarketing === 'true' && <Route
        path={pageRoutes.marketingGeneric}
        element={<MarketingPage
          user={currentUser}
          setUser={setCurrentUser}
          purchaseID={purchaseID}
          setPurchaseID={setPurchaseID}
          setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
        />}
      />}
      <Route
        path={pageRoutes.collegeDataVisualization}
        element={<CollegeDataVisualization
          user={currentUser}
          setUser={setCurrentUser}
          collegesId={collegesId}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          collegeDataVisLocation={collegeDataVisLocation}
          setCollegeDataVisLocation={setCollegeDataVisLocation}
        />} />
      <Route path={pageRoutes.ecSearch}
        element={<ECSearchPage
          user={currentUser}
          setUser={setCurrentUser}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          setECName={setECName}
          setECNumAlumni={setECNumAlumni}
          setECID={setECID}
          setPurchaseID={setPurchaseID}
          setShowNavAfterPayment={setShowNavAfterPayment}
          ecSearchLocation={ecSearchLocation}
          setECSearchLocation={setECSearchLocation} />} />
      {/* <Route
        path={pageRoutes.ecDetailGeneric}
        element={
          <ECDetailPage
            user={currentUser}
            setUser={setCurrentUser}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setNavigateTo={setNavigateTo}
            setECName={setECName}
            setECNumAlumni={setECNumAlumni}
            setECID={setECID}
            setPurchaseID={setPurchaseID}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            ecName={ecName}
            ecSearchLocation={ecSearchLocation}
            idToSend={0}
          />} /> */}
      <Route
        path={pageRoutes.ecPremiumInsightsGeneric}
        element={
          <ECPremiumInsights
            user={currentUser}
            cip={cip}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
          />} />
      <Route
        path={pageRoutes.majorSearch}
        element={
          <MajorSearchPage
            user={currentUser}
            setUser={setCurrentUser}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setNavigateTo={setNavigateTo}
            majorSearchLocation={majorSearchLocation}
            setMajorSearchLocation={setMajorSearchLocation}
          />} />
      {/* <Route
        path={pageRoutes.majorDetailGeneric}
        element={
          <MajorDetailPage
            user={currentUser}
            setUser={setCurrentUser}
            collegeListLength={collegesId.length}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setNavigateTo={setNavigateTo}
            majorSearchLocation={majorSearchLocation}
          />} /> */}
      {advisorsCheck === 'true' && <Route
        path={pageRoutes.advisorSearch}
        element={<AdvisorSearchPage
          user={currentUser}
          collegesId={collegesId}
          cip={cip}
          ecActivities={ecActivities}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setAdvisorID={setAdvisorID}
          advisorSearchLocation={advisorSearchLocation}
          setAdvisorSearchLocation={setAdvisorSearchLocation}
        />} />}
      {advisorsCheck === 'true' && <Route
        path={pageRoutes.advisorDetailGeneric}
        element={
          <AdvisorDetailPage
            user={currentUser}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setPurchaseID={setPurchaseID}
            setAdvisorID={setAdvisorID}
            advisorSearchLocation={advisorSearchLocation}
          />} />}
      <Route
        path={pageRoutes.signUp}
        element={<SignUpPage
          setUser={setCurrentUser}
        />}
      />
      <Route
        path={pageRoutes.signIn}
        element={<SignInPage />}
      />
      <Route
        path={pageRoutes.privacyPolicy}
        element={<PrivacyPolicy />}
      />
      <Route
        path={pageRoutes.termsOfService}
        element={<TermsOfService />}
      />
      <Route
        path={pageRoutes.about}
        element={<About />}
      />
      <Route
        path={pageRoutes.faq}
        element={<FAQ />}
      />
      <Route
        path={pageRoutes.contact}
        element={<Contact />}
      />
      <Route path='*' element={<ErrorsPage />} />
    </>
  )

  const privateRoutes = (
    <>
      <Route
        path={pageRoutes.testScoreStrategy}
        element={<TestScoreStrategy
          user={currentUser}
          setUser={setCurrentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
        />} />
      <Route
        path={pageRoutes.userProfileGeneric}
        element={
          <UserProfile
            user={currentUser}
            setUser={setCurrentUser}
            userCollegeInterest={userCollegeInterest}
            setUserCollegeInterest={setUserCollegeInterest}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setCollegeDetailSelectedTab={setCollegeDetailSelectedTab} />
        } />
      <Route
        path='upgrade-tier'
        element={
          <UpgradeTierPage
          // setPrompt={setPrompt}
          // setNavigateTo={setNavigateTo}
          />} />
    </>
  )

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ScrollToTop />
      <Header
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo} />
      <Routes>
        <Route element={<App />}>
          {/* <Route path='error/*' element={<ErrorsPage />} />
          <Route path='*' element={<Navigate to='/error/404' />} /> */}
          <Route path='error/*' element={<Home
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setCurrentUser={setCurrentUser} />} />
          <Route path='*' element={<Navigate to='/home' />} />
          <Route path='logout' element={<Logout />} />
          {apiToken ? (
            <>
              <Route index element={<Navigate to='/home' />} />
              {allRoutes}
              {privateRoutes}
            </>
          ) : (
            <>
              <Route index element={<Navigate to='/home' />} />
              {allRoutes}
            </>
          )}

        </Route>
      </Routes>

      {/* Modals */}
      <SignInModal
        prompt={prompt}
        setPrompt={setPrompt}
        navigateTo={navigateTo}
        setNavigateTo={setNavigateTo}
        setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
      />

      <SignUpModal
        prompt={prompt}
        setPrompt={setPrompt}
        navigateTo={navigateTo}
        setNavigateTo={setNavigateTo}
        setCollegeDetailSelectedTab={setCollegeDetailSelectedTab} />

      <PurchaseDescriptionModal
        purchaseID={purchaseID}
        collegeName={purchaseModalCollegeName}
        collegeListLength={collegesId.length > 0 ? collegesId.length : 100}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        ecID={ecID}
        ecName={ecName}
        ecNumAlumni={ecNumAlumni}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        advisorID={advisorID}
        currentUser={currentUser} />

      <PremiumPreviewModal
        purchaseID={purchaseID} />

      <UpgradeAccountModal />

      {/* ID: add_payment_method_modal */}
      <AddCardModal
        upgradeUserGroupAccessCode={upgradeUserGroupAccessCode}
        setSelectedTab={setCollegeDetailSelectedTab}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        showNavAfterPayment={showNavAfterPayment}
        setShowNavAfterPayment={setShowNavAfterPayment}
        ecID={ecID}
        ecName={ecName}
        advisorID={advisorID}
      />
      {/*  */}

      <ScreenWidthAlertModal />

      <EmailVerificationRequestModal currentUser={currentUser} />

      <Footer
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo} />
    </BrowserRouter>
  )
}

export { AppRoutes }
