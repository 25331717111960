import axios from "axios"
import * as dictionary from '../components/dictionary'
import { zipcodeMap } from '../components/zipcodeMap'
import { collegeMap } from "../components/collegeMap"
import { useState, useEffect } from "react"
import { ECSearchAside } from "../components/ECSearch/ECSearchAside"
import { ECCard } from "../components/ECSearch/ECCard"
import { useNavigate } from "react-router"
import { useAuth } from "../modules/auth"
import { LoadingIndicator } from "../components/LoadingIndicator"

const ECSearchPage = (props) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()
  //////// Set states of variables ////////
  const [ec, setEC] = useState<any>([])
  const [filteredEC, setFilteredEC] = useState<any>([])
  const [filteredResults, setFilteredResults] = useState(false)
  const [nameOptions, setNameOptions] = useState<any>([]);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [collegeOptions, setCollegeOptions] = useState<any>([]);
  const [sortKey, setSortKey] = useState<any>('name')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')
  const [allECPremiumInsights, setAllECPremiumInsights] = useState<any>([])
  const [guestClickShowOnlyInterest, setGuestClickShowOnlyInterest] = useState(false)
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  const [onlyPremiumsSelect, setOnlyPremiumsSelect] = useState(false)
  const [loadingComplete, setLoadingComplete] = useState(false)
  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorECs, setAdvisorECs] = useState<any>([])

  //Filter states
  const [nameFilter, setNameFilter] = useState<any>((item) => {
    return item => item
  })
  const [typeFilter, setTypeFilter] = useState<any>((item) => {
    return item => item
  })
  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [interestAreaFilter, setInterestAreaFilter] = useState<any>((item) => {
    return item => item
  })
  const [majorFilter, setMajorFilter] = useState<any>((item) => {
    return item => item
  })
  const [careerFilter, setCareerFilter] = useState<any>((item) => {
    return item => item
  })
  const [formatFilter, setFormatFilter] = useState<any>((item) => {
    return item => item
  })
  const [dateFilter, setDateFilter] = useState<any>((item) => {
    return item => item
  })
  const [gradeFilter, setGradeFilter] = useState<any>((item) => {
    return item => item
  })
  const [schoolLevelFilter, setSchoolLevelFilter] = useState<any>((item) => {
    return item => item
  })
  const [ageFilter, setAgeFilter] = useState<any>((item) => {
    return item => item
  })
  const [costFilter, setCostFilter] = useState<any>((item) => {
    return item => item
  })
  const [locationFilter, setLocationFilter] = useState<any>((item) => {
    return item => item
  })
  const [impactFilter, setImpactFilter] = useState<any>((item) => {
    return item => item
  })
  const [showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter] = useState<any>((item) => {
    return item => item
  })
  const [showOnlyPremiumsFilter, setShowOnlyPremiumsFilter] = useState<any>((item) => {
    return item => item
  })

  //////// Functions and event handlers ////////
  const getNameOptions = (ec) => {
    let res: any = []
    ec.forEach((ec) => {
      res.push({ value: ec.name, label: ec.name })
    });
    setNameOptions(res)
  }

  const getLocations = async (filteredEC) => {

    // zip code is in string format!!
    // Pull each zipcode from the ec data
    let allLocations: any = []
    filteredEC.forEach((ec) => {
      if (ec.locations !== null) {
        ec.locations.map((loc) => allLocations.push(loc))
      }
    })

    //  Create a set of that data to hold an array of unique zipcodes
    allLocations = [...new Set(allLocations)]
    allLocations.sort((a, b) => a - b)

    // Initialize the array to set the location options to
    let res: any = []

    let cityStates: any = []

    // For each unique zipcode:
    allLocations.forEach(loc => {

      // Find an object in the zipcodeMap array matching the zipcode field
      const zipcodeFound = zipcodeMap.find((item) => item.zipcode == loc)

      // If the cityStates array doesn't already have that cityState, get the zipcodes associated with that location
      if (zipcodeFound && !cityStates.includes(zipcodeFound.cityState)) {

        cityStates.push(zipcodeFound.cityState)

        let groupedZips: any = []

        zipcodeMap.forEach((item) => {

          if (item.cityState == zipcodeFound.cityState) {
            groupedZips.push(item.zipcode)
          }
        })

        if (groupedZips && groupedZips.length > 0) {

          groupedZips = [...new Set(groupedZips)]

          res.push({ value: groupedZips, label: `${zipcodeFound.cityState}` })
        }

        else {
          res.push({ value: loc, label: `${zipcodeFound.cityState}` })
        }
        // res.push({ value: loc, label: `${zipcodeFound.cityState}` })
      }

      // If a result is not found, just return the string `loc`
      else if (!zipcodeFound) {
        res.push({ value: loc, label: loc })
      }

    });

    // Sort the location options alphabetically 
    res = res.sort((a, b) => a.label.localeCompare(b.label))

    setLocationOptions(res)
  }

  const getInsights = async () => {
    let res = await axios.get(`${dictionary.URL}/ec_insights/`)
    setAllECPremiumInsights(res.data)
  }

  const getData = async () => {

    let ecCache: string | null = localStorage.getItem('dantech-ec')

    const getColleges = (collegeMap, ecData) => {
      let res: any = []
      collegeMap.forEach((col) => {

        let foundECSponsorCollegeID = ecData.find((item) => item.sponsorCollegeID != null && parseInt(col.id) == item.sponsorCollegeID)

        if (foundECSponsorCollegeID) {
          res.push({ value: col.id, label: col.name })
        }
      });

      res = res.sort((a, b) => a.label.localeCompare(b.label))

      setCollegeOptions(res)
    }

    const handleSetAdvisors = async () => {
      let advisorCache: string | null = localStorage.getItem('dantech-advisors')

      if (!advisorCache) {
        // axios request to get advisors
        let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
        let advisorECs: any = []
        res.data.map(advisor => {
          advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
        })

        try {
          localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
        } catch (error) {
          console.log('Error:', error)
        }

        setAdvisorData(res.data)
        setAdvisorECs([...new Set(advisorECs)])

      } else {
        try {

          const advisorDataParsed = JSON.parse(advisorCache)
          setAdvisorData(advisorDataParsed)

          let advisorECs: any = []
          advisorDataParsed.map(advisor => {
            advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
          })
          setAdvisorECs([...new Set(advisorECs)])

        } catch (error) {

          console.log('Error grabbing data, using axios instead:', error)
          let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
          let advisorECs: any = []
          res.data.map(advisor => {
            advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
          })

          try {
            localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
          } catch (error) {
            console.log('Error:', error)
          }

          setAdvisorData(res.data)
          setAdvisorECs([...new Set(advisorECs)])

        }
      }
    }

    await handleSetAdvisors()

    if (!ecCache) {
      // Get and set EC data
      let res = await axios.get(`${dictionary.URL}/ec_activity/`);
      try {
        localStorage.setItem('dantech-ec', JSON.stringify(res.data))
      } catch (error) {
        console.log('Error:', error)
      }
      // console.log('ec data:', res.data)
      getColleges(collegeMap, res.data)
      setEC(res.data)
      setFilteredEC(res.data);
      setFilteredResults(true);
    }

    else if (ecCache) {

      try {
        const ecCacheParsed = JSON.parse(ecCache)

        // console.log('ecCacheParsed', ecCacheParsed)

        getColleges(collegeMap, ecCacheParsed)
        setEC(ecCacheParsed)
        setFilteredEC(ecCacheParsed);
        setFilteredResults(true);
      } catch (error) {
        console.log('Error grabbing data, using axios instead:', error)

        // Get and set EC data
        let res = await axios.get(`${dictionary.URL}/ec_activity/`);
        try {
          localStorage.setItem('dantech-ec', JSON.stringify(res.data))
        } catch (error) {
          console.log('Error:', error)
        }
        // console.log('ec data:', res.data)
        getColleges(collegeMap, res.data)
        setEC(res.data)
        setFilteredEC(res.data);
        setFilteredResults(true);
      }
    }
  }

  //////// useEffects ////////
  useEffect(() => {

    dictionary.updateTitleAndDescription(dictionary.pageRoutes.ecSearch)

    try {

      if (loadingComplete == true) {
        setLoadingComplete(false)
      }

      getData();
      getInsights();
      // getColleges(collegeMap);
    } catch (error) {

      console.log('Error running EC Search page fetch requests', error);
      throw error;

    } finally {
      setLoadingComplete(true)
    }
  }, [])

  useEffect(() => {
    if (ec.length > 0) {
      getNameOptions(ec);
      getLocations(ec);
      // updateCollegeOptions(filteredEC, collegeOptions)
    }
  }, [ec])

  useEffect(() => {
    const sortAndFilter = (nameFilter, typeFilter, collegeFilter, interestAreaFilter, majorFilter, careerFilter, formatFilter, dateFilter, gradeFilter, ageFilter, costFilter, locationFilter, impactFilter, schoolLevelFilter, showOnlyPremiumsFilter, sortOrder, sortKey) => {

      if (onlyInterestsSelect == true && (!currentUser || !props.user)) {
        return setGuestClickShowOnlyInterest(true)
      }

      else {
        setGuestClickShowOnlyInterest(false)
      }

      let res: any = ec

      // No Date Filter set, need to update components
      res = res.filter(nameFilter).filter(typeFilter).filter(collegeFilter).filter(interestAreaFilter).filter(formatFilter).filter(gradeFilter).filter(ageFilter).filter(locationFilter).filter(costFilter).filter(dateFilter).filter(schoolLevelFilter).filter(impactFilter).filter(showOnlyMyInterestsFilter).filter(showOnlyPremiumsFilter)

      // res = res.filter(dateFilter).filter(majorFilter).filter(careerFilter);

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      sortOrder === 'Descending' ?
        res = res.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
        res = res.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))

      setFilteredEC(res)
      if (filteredResults === false) {
        setFilteredResults(true)
      }
    }

    sortAndFilter(nameFilter, typeFilter, collegeFilter, interestAreaFilter, majorFilter, careerFilter, formatFilter, dateFilter, gradeFilter, ageFilter, costFilter, locationFilter, impactFilter, schoolLevelFilter, showOnlyPremiumsFilter, sortOrder, sortKey);

  }, [showOnlyPremiumsFilter, showOnlyMyInterestsFilter, nameFilter, typeFilter, collegeFilter, interestAreaFilter, majorFilter, careerFilter, formatFilter, dateFilter, gradeFilter, ageFilter, costFilter, locationFilter, impactFilter, schoolLevelFilter, sortOrder, sortKey])

  const returnResults = (filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, ec, loadingComplete) => {

    if (loadingComplete == false) {
      return <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
        <LoadingIndicator text={"Loading Information On Extracurricular Activities..."} />
      </div>
    }

    else {
      switch (true) {
        case (onlyInterestsSelect === true && ec.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>Please save activities to your interest list in order for them to appear here.</div>
          </div>
        case guestClickShowOnlyInterest:
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>Please first log-in to your account in order for your saved colleges to appear here.</div>
          </div>
        case filteredResults:
          return <ECCard
            filteredEC={filteredEC}
            sortKey={sortKey}
            setSortKey={setSortKey}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            user={props.user}
            setUser={props.setUser}
            setNavigateTo={props.setNavigateTo}
            setECName={props.setECName}
            setECNumAlumni={props.setECNumAlumni}
            setECID={props.setECID}
            setPurchaseID={props.setPurchaseID}
            allECPremiumInsights={allECPremiumInsights}
            setShowNavAfterPayment={props.setShowNavAfterPayment}
            setPrompt={props.setPrompt}
            advisorECs={advisorECs}
          />
        case !filteredResults && ec && ec.length > 0:
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>No results meet your criteria</div>
          </div>
      }

    }

  }

  const ECSearchHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Extracurriculars</span>

        <div className="dca-search-header-bttn-container">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('crafting-an-extracurriculars-profile'))}
            className='dca-search-header-bttn'
          >Advice: Selecting Extracurriculars</button>
        </div>
      </div>

      <span className="dca-search-description">Discover extracurricular activities that align with your personal interests and strengthen your application profile.</span>
    </div>
  )

  return (
    <div>
      {ECSearchHeader}
      <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
        <ECSearchAside
          filteredEC={filteredEC}
          setFilteredEC={setFilteredEC}
          nameOptions={nameOptions}
          locationOptions={locationOptions}
          collegeOptions={collegeOptions}
          setNameFilter={setNameFilter}
          setTypeFilter={setTypeFilter}
          setCollegeFilter={setCollegeFilter}
          setInterestAreaFilter={setInterestAreaFilter}
          setMajorFilter={setMajorFilter}
          setCareerFilter={setCareerFilter}
          setFormatFilter={setFormatFilter}
          setDateFilter={setDateFilter}
          setGradeFilter={setGradeFilter}
          setAgeFilter={setAgeFilter}
          setCostFilter={setCostFilter}
          setLocationFilter={setLocationFilter}
          setImpactFilter={setImpactFilter}
          setSchoolLevelFilter={setSchoolLevelFilter}
          ecSearchLocation={props.ecSearchLocation}
          setECSearchLocation={props.setECSearchLocation}
          sortKey={sortKey}
          sortOrder={sortOrder}
          setSortKey={setSortKey}
          setSortOrder={setSortOrder}
          setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          setShowOnlyPremiumsFilter={setShowOnlyPremiumsFilter}
          onlyPremiumsSelect={onlyPremiumsSelect}
          setOnlyPremiumsSelect={setOnlyPremiumsSelect}
          allECPremiumInsights={allECPremiumInsights}
        />
        {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, ec, loadingComplete)}
      </div>
    </div>
  )
}

export { ECSearchPage }
