import { RankColorRange } from "../../RankColorRange";
import { RankIndicator } from "../../RankIndicator"
import { SpecializedRankingModal } from "./Modals/SpecializedRankingModal";
import { URL } from "../../dictionary";
import { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "../../../modules/auth";
import { TooltipComponent } from "../../Tooltip";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SpecializedRankings = ({ college, collegeListLength, setSelectedTab }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  if (apiToken === undefined) {
    setSelectedTab('Admission Info')
  }

  const numberConverter = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    else {
      return '-'
    }
  }

  const returnValue = (value) => {
    if (value || value != null) {

      return numberConverter(value)
    }

    else {
      return '-'
    }
  }

  const [modalTitle, setModalTitle] = useState('Nobel Prizes')
  const [collegeData, setCollegeData] = useState<any>([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const returnViewRankingsTrigger = (modalTitle, rank) => {

    return (<div
      style={{ cursor: 'pointer' }}
      data-bs-toggle="modal"
      data-bs-target="#view_specialized_rankings_modal"
      className="align-self-center"
      onClick={() => {
        setModalTitle(modalTitle);
      }}
    >
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {`See ${modalTitle} rankings across all colleges`}
          </Tooltip>
        )}
        placement="top"
      ><div><RankIndicator rank={rank} /></div>
      </OverlayTrigger>
    </div>)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (dataLoaded === true) {
          setDataLoaded(false)
        }

        // const collegeAxios = axios.get(`${URL}/colleges/0-to-${collegeListLength}`)
        const collegeAxios = axios.get(`${URL}/colleges/all`)

        const [collegeRes] = await Promise.all([
          collegeAxios
        ]);

        await Promise.all([
          setCollegeData(collegeRes.data)
        ]);

        console.log('College data for modal:', collegeRes.data)

        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);

  let [federalRnDValue, nobelValue, usPatentsValue, foundersValue, macarthurValue, fieldsValue, fortune5Value, businessTotalValue, business1KValue, governorsValue, senatorsValue, rhodesScholarsValue, marshallScholarsValue, median4yrEarningsValue, earlyCareerPayValue, midCareerPayValue, billionairesValue]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

  let [federalRnDRank, nobelRank, usPatentsRank, foundersRank, macarthurRank, fieldsRank, fortune5Rank, businessTotalRank, business1KRank, governorsRank, senatorsRank, rhodesScholarsRank, marshallScholarsRank, median4yrEarningsRank, earlyCareerPayRank, midCareerPayRank, billionairesRank]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  const latestYear = new Date().getFullYear()

  let highestAwards: any = [
    { name: 'Nobel Prizes', value: nobelValue, rank: nobelRank },
    { name: 'Macarthur Fellows', value: macarthurValue, rank: macarthurRank },
    { name: 'Fields Medals', value: fieldsValue, rank: fieldsRank },
  ]

  let researchInnovation: any = [
    { name: 'Average Annual R&D Spend', value: federalRnDValue, rank: federalRnDRank },
    { name: `Patents Issued (${latestYear})`, value: usPatentsValue, rank: usPatentsRank },
    { name: 'Founders of VC-backed companies', value: foundersValue, rank: foundersRank },
  ]

  let alumniSuccessBusiness: any = [
    { name: 'Fortune 500 CEOs', value: fortune5Value, rank: fortune5Rank },
    { name: 'Business Leaders (Total)', value: businessTotalValue, rank: businessTotalRank },
    { name: 'Business Leaders (Per 1K Students)', value: business1KValue, rank: business1KRank },
  ]

  let alumniSuccessPolitics: any = [
    { name: 'US Governors', value: governorsValue, rank: governorsRank },
    { name: 'US Senators', value: senatorsValue, rank: senatorsRank },
  ]

  let alumniWinningScholarships: any = [
    { name: 'Rhodes Scholars', value: rhodesScholarsValue, rank: rhodesScholarsRank },
    { name: 'Marshall Scholars', value: marshallScholarsValue, rank: marshallScholarsRank },
  ]

  let earningsWealth: any = [
    { name: 'Median 4-Year Earnings', value: median4yrEarningsValue, rank: median4yrEarningsRank },
    { name: 'Early Career Pay', value: earlyCareerPayValue, rank: earlyCareerPayRank },
    { name: 'Mid Career Pay', value: midCareerPayValue, rank: midCareerPayRank },
    { name: 'US Billionaires', value: billionairesValue, rank: billionairesRank },
  ]

  let specialized: any = [
    { name: 'Federal R&D spend ($M)', value: federalRnDValue, rank: federalRnDRank },

    { name: 'Nobel Prizes', value: nobelValue, rank: nobelRank },

    { name: 'US Patents Issued', value: usPatentsValue, rank: usPatentsRank },

    { name: 'Founders of VC-backed companies', value: foundersValue, rank: foundersRank },

    { name: 'Macarthur Fellows', value: macarthurValue, rank: macarthurRank },

    { name: 'Fields Medal', value: fieldsValue, rank: fieldsRank },

    { name: 'Fortune 500 CEOs', value: fortune5Value, rank: fortune5Rank },

    { name: 'Business Leaders', value: businessTotalValue, rank: businessTotalRank },
  ]

  if (college) {

    //Highest Awards/Recognition
    nobelValue = college.nobelPrizesValue
    nobelRank = college.nobelPrizesRank
    macarthurValue = college.macarthurValue
    macarthurRank = college.macarthurRank
    fieldsValue = college.fieldsMedalValue
    fieldsRank = college.fieldsMedalRank

    //Research Innovation
    federalRnDValue = (college.averageRDspendValue / 1000).toFixed(1)
    federalRnDRank = college.averageRDspendRank
    usPatentsValue = college.patentsIssuedValue
    usPatentsRank = college.patentsIssuedRank
    foundersValue = college.foundersVcCosValue
    foundersRank = college.foundersVcCosRank

    //Alumni Success In Business
    fortune5Value = college.fortune500CeoValue
    fortune5Rank = college.fortune500CeoRank
    businessTotalValue = college.bizLeadersTotalValue
    businessTotalRank = college.bizLeadersTotalRank
    business1KValue = college.bizLeadersPer1KValue
    business1KRank = college.bizLeadersPer1KRank

    //Alumni Success in Politics
    governorsValue = college.governorsValue
    governorsRank = college.governorsRank
    senatorsValue = college.senatorsValue
    senatorsRank = college.senatorsRank

    //Alumni Winning Scholarships
    rhodesScholarsValue = college.rhodesScholarsValue
    rhodesScholarsRank = college.rhodesScholarsRank
    marshallScholarsValue = college.marshallScholarsValue
    marshallScholarsRank = college.marshallScholarsRank

    //Earnings/Wealth
    median4yrEarningsValue = college.median4yrEarnings
    median4yrEarningsRank = college.median4yrEarningsRank
    earlyCareerPayValue = college.earlyCareerPay
    earlyCareerPayRank = college.earlyCareerPayRank
    midCareerPayValue = college.midCareerPay
    midCareerPayRank = college.midCareerPayRank
    billionairesValue = college.billionairesValue
    billionairesRank = college.billionairesRank


    //convert averageRDspendValye to single digit ($M) format
    federalRnDValue = `$${numberConverter(federalRnDValue)}`
    median4yrEarningsValue = `$${numberConverter(median4yrEarningsValue)}`
    earlyCareerPayValue = `$${numberConverter(earlyCareerPayValue)}`
    midCareerPayValue = `$${numberConverter(midCareerPayValue)}`

    specialized = [
      { name: 'Federal R&D spend ($M)', value: federalRnDValue, rank: federalRnDRank },

      { name: 'Nobel Prizes', value: nobelValue, rank: nobelRank },

      { name: 'US Patents Issued', value: usPatentsValue, rank: usPatentsRank },

      { name: 'Founders of VC-backed companies', value: foundersValue, rank: foundersRank },

      { name: 'Macarthur Fellows', value: macarthurValue, rank: macarthurRank },

      { name: 'Fields Medal', value: fieldsValue, rank: fieldsRank },

      { name: 'Fortune 500 CEOs', value: fortune5Value, rank: fortune5Rank },

      { name: 'Business Leaders', value: businessTotalValue, rank: businessTotalRank },
    ]

    highestAwards = [
      { name: 'Nobel Prizes', value: nobelValue, rank: nobelRank },
      { name: 'Macarthur Fellows', value: macarthurValue, rank: macarthurRank },
      { name: 'Fields Medals', value: fieldsValue, rank: fieldsRank },
    ]

    researchInnovation = [
      { name: 'Average Annual R&D Spend', value: federalRnDValue, rank: federalRnDRank },
      { name: `Patents Issued`, value: usPatentsValue, rank: usPatentsRank },
      { name: 'Founders of VC-backed companies', value: foundersValue, rank: foundersRank },
    ]

    alumniSuccessBusiness = [
      { name: 'Fortune 500 CEOs', value: fortune5Value, rank: fortune5Rank },
      { name: 'Business Leaders (Total)', value: businessTotalValue, rank: businessTotalRank },
      { name: 'Business Leaders (Per 1K Students)', value: business1KValue, rank: business1KRank },
    ]

    alumniSuccessPolitics = [
      { name: 'US Governors', value: governorsValue, rank: governorsRank },
      { name: 'US Senators', value: senatorsValue, rank: senatorsRank },
    ]

    alumniWinningScholarships = [
      { name: 'Rhodes Scholars', value: rhodesScholarsValue, rank: rhodesScholarsRank },
      { name: 'Marshall Scholars', value: marshallScholarsValue, rank: marshallScholarsRank },
    ]

    earningsWealth = [
      { name: 'Median 4-Year Earnings', value: median4yrEarningsValue, rank: median4yrEarningsRank },
      { name: 'Early Career Pay', value: earlyCareerPayValue, rank: earlyCareerPayRank },
      { name: 'Mid Career Pay', value: midCareerPayValue, rank: midCareerPayRank },
      { name: 'US Billionaires', value: billionairesValue, rank: billionairesRank },
    ]

  }

  const returnTooltipText = (name) => {
    switch (true) {
      case name == 'Nobel Prizes':
        return `Cumulative number of Nobel Prizes awarded to individuals affiliated with ${college.name} at the time of the Nobel Prize announcement. Source: Nobel Prize website. Data as of Q4 2023.`
      case name == 'Macarthur Fellows':
        return `Cumulative number of Macarthur Fellows indicated as associated with ${college.name}. Source: Macarthur Foundation website. Data as of Q4 2023.`
      case name == 'Fields Medals':
        return `Cumulative number of Fields Medals awarded to individuals affiliated with ${college.name} at the time of the award. Source: Wikipedia. Data as of Q4 2023.`
      case name == 'Average Annual R&D Spend':
        return `Average annual R&D spend for the last three years of available data. Source: National Center for Science and Engineering Statistics. Data as of Q4 2023.`
      case name == 'Patents Issued':
        return `Number of granted U.S. utility patents by university in 2023. In cases where data is provided for a group of schools (i.e. University of California system) the total figure is displayed per member institution. Source: National Academy of Inventors.`
      case name == 'Founders of VC-backed companies':
        return `Number of founders whose companies received venture capital funding between 2013 and 2023. Source: PitchBook. Data as of Q4 2023.`
      case name == 'Fortune 500 CEOs':
        return `Number of CEOs of companies currently ranked in the Fortune 500 who attended ${college.name} for undergraduate education. Source: Online individual profile data. Data as of Q4 2023.`
      case name == 'Business Leaders (Total)':
        return `Number of business leaders (with job titles including CEO, CFO, COO, CTO, CIO and CSO) who attended ${college.name}. Analysis excludes some smaller universities (with <25K online alumni profiles.) Source: Resume.io. Data as of December 2022.`
      case name == 'Business Leaders (Per 1K Students)':
        return `Number of business leaders (with job titles including CEO, CFO, COO, CTO, CIO and CSO) who attended ${college.name} per thousand students. Analysis excludes some smaller universities (with <25K online alumni profiles.) Source: Resume.io. Data as of December 2022.`
      case name == 'US Governors':
        return `Number of current sitting US Governors who attended ${college.name} for undergraduate education. Source: Wikipedia. Data as of Q4 2023.`
      case name == 'US Senators':
        return `Number of current sitting US Senators who attended ${college.name} for undergraduate education. Source: Wikipedia. Data as of Q4 2023.`
      case name == 'Rhodes Scholars':
        return `Number of Rhodes Scholarship recipients between 2019 - 2024 who attended ${college.name} for undergraduate education. Source: Rhodes Trust website. Data as of Q4 2023.`
      case name == 'Marshall Scholars':
        return `Number of Marshall Scholarship recipients between 2019 - 2023 who attended ${college.name} for undergraduate education. Source: Marshall Scholarships website. Data as of Q4 2023.`
      case name == 'Median 4-Year Earnings':
        return `Median earnings of bachelors degree graduates from ${college.name} working and not enrolled 4 years after completing undergraduate program. Source: US Department of Education College Scorecard. Data as of Q4 2023.`
      case name == 'Early Career Pay':
        return `Median earnings of bachelors degree graduates from ${college.name} with 0-5 years experience. Source: Payscale. Data as of Q4 2023.`
      case name == 'Mid Career Pay':
        return `Median earnings of bachelors degree graduates from ${college.name} with 10+ years experience. Source: Payscale. Data as of Q4 2023.`
      case name == 'US Billionaires':
        return `Number of billionaires included in the top 500 of Forbes list who attended ${college.name} for undergraduate education. Source: Forbes and online individual profile data. Data as of Q4 2023.`
    }

  }

  const highestAwardsComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Academic Awards/Faculty Recognition</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {highestAwards.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div
                className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  const researchInnovationComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Research/Innovation</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {researchInnovation.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  const alumniSuccessBusinessComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Alumni Success In Business</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {alumniSuccessBusiness.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  const alumniSuccessPoliticsComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Alumni Sucess in Politics</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {alumniSuccessPolitics.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  const alumniWinningScholarshipsComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Alumni Receiving Scholarships</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {alumniWinningScholarships.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  const earningsWealthComponent = (
    <ul className='d-flex flex-column justify-content-between' style={{ width: '55vw', maxWidth: '750px', marginTop: '30px', marginLeft: '20px' }}>

      <div style={{ fontSize: '1.25rem' }}>Earnings/Wealth</div>

      <li className='d-flex flex-column'>
        <div className='mb-5 d-flex flex-row justify-content-end'>
          <div className="align-self-center"></div>
          <div className="me-5 dca-header-spacing-B">VALUE</div>
          <div className="dca-header-spacing-B">RANK</div>

        </div>
      </li>

      {earningsWealth.map((res, index) => (
        <li key={index} className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className="align-self-center">{res.name} <span className="ms-2"><TooltipComponent content={returnTooltipText(res.name)} /></span></div>
            <div className="d-flex flex-row">
              <div className="dca-college-detail-SR-value">
                {returnValue(res.value)}
              </div>
              {returnViewRankingsTrigger(res.name, res.rank)}
            </div>
          </div>
          <div className={index > specialized.length - 2 ? 'm-2' : 'm-2 separator'}></div>
        </li>
      ))}

    </ul>
  )

  return (
    <div className="card shadow dca-font-M" style={{ marginTop: '40px' }}>

      {/* <li className="d-flex justify-content-end" style={{ margin: '20px 20px 20px 20px' }}>
        <RankColorRange />
      </li> */}

      <div className='d-flex justify-content-end' style={{ width: '55vw', maxWidth: '750px', margin: '20px' }}><RankColorRange /></div>

      {highestAwardsComponent}

      {researchInnovationComponent}

      {alumniSuccessBusinessComponent}

      {alumniSuccessPoliticsComponent}

      {alumniWinningScholarshipsComponent}

      {earningsWealthComponent}

      <SpecializedRankingModal
        collegeData={collegeData}
        dataLoaded={dataLoaded}
        modalTitle={modalTitle}
        latestYear={latestYear}
        numberConverter={numberConverter} />

    </div>)

}



export { SpecializedRankings }
