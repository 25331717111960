import axios from "axios"
import { URL, FRONTEND_URL, pageRoutes, specializedRankingsPriceID, rdSpendPriceID, safetyPriceID, ecPremiumPriceIDs, advisorPriceIDs, returnItemFromProductID } from "../../components/dictionary"
import { getUserByToken } from "../../modules/auth/core/_requests"
import { sendAdvisorConsultationPurchaseAlert } from "../../modules/auth/core/_requests"

// expiryDate: string removed
export const handleUpdateUserTransactions = async (apiToken: string, productID: string | undefined) => {

  const postRequest = async (userEmail, productID) => {

    const getStripePrice = async (priceID) => {
      let res = await axios.get(`${URL}/get-price/${priceID}`)
      let unitAmount = res.data.unit_amount
      if ((unitAmount / 100) > 0) {
        unitAmount = (unitAmount / 100).toFixed(2)
      }
      return unitAmount
    }

    const handleNav = (priceID) => {

      if ([specializedRankingsPriceID, rdSpendPriceID, safetyPriceID].includes(priceID)) {

        switch (true) {
          case priceID == specializedRankingsPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
          case priceID == rdSpendPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
          case priceID == safetyPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
        }

      } else {

        try {
          let ecObj = ecPremiumPriceIDs.find(item => item.priceID == priceID)
          let advisorObj = advisorPriceIDs.find(item => item.priceID == priceID)

          if (ecObj) {
            return (`${FRONTEND_URL}${pageRoutes.ecPremiumInsightsWithId(ecObj.activityID)}`)
          }

          if (advisorObj) {
            return (`${FRONTEND_URL}${pageRoutes.advisorDetailWithId(advisorObj.advisorID)}`)
          }

        } catch (error) {
          console.log('Error converting EC productID to a valid product', error)
        }

      }

    }

    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    let purchaseDate = `${month}/${day}/${year}`;
    let purchaseItem = returnItemFromProductID(productID);
    let purchaseTotal = await getStripePrice(productID);
    let purchaseURL = handleNav(productID)
    let advisorText = ""

    if (advisorPriceIDs.some(advisorObj => advisorObj.priceID == productID)) {
      advisorText = "The next step is you will receive an email from NextFour with details for scheduling your meeting. If you have any questions, please contact us at advisors@nextfour.ai."
    }

    let postData = {
      userEmail: userEmail,
      productID: productID,
      purchaseItem: purchaseItem,
      purchaseDate: purchaseDate,
      purchaseTotal: `$${purchaseTotal}`,
      purchaseURL: purchaseURL,
      advisorText: advisorText
    }

    await axios.post(`${URL}/user_transactions/create/`, postData)

    // Then, if advisor consult was purchased, send email alert using sendAdvisorConsultationPurchaseAlert
    if (postData.advisorText != "") {

      let advisorObj = advisorPriceIDs.find(item => item.priceID == productID)
      let advisorNamePreview = advisorObj?.description.split(' ').slice(-1)[0] || ''

      if (advisorObj) {
        await sendAdvisorConsultationPurchaseAlert(advisorNamePreview, advisorObj?.advisorID, `$${purchaseTotal}`, `Advisor Consultation: ${advisorObj?.description}`, userEmail)
      }

    }

  }

  try {

    const { data: user } = await getUserByToken(apiToken)

    let userEmail = user.email

    await postRequest(userEmail, productID);

  } catch (error) {

    console.log(error);

    throw (error)
  }

}